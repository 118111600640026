import './index.scss';
import Image from 'react-bootstrap/Image'
import logo from '../../static/images/logo.png'
import NavList from './components/navList';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function HeaderContainer() {
  return (
          <NavList></NavList>
  );
}

export default HeaderContainer;
