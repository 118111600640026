import axios from './axios'


export const getNonce = (data) => {
  return axios.request({
      url:'/api/login/get_nonce',
      data,
      method:'post'
  })
}
export const login = (data) => {
  return axios.request({
      url:'/api/login',
      data,
      method:'post'
  })
}
export const createUser = (data) => {
  return axios.request({
      url:'/api/user/create',
      data,
      method:'post'
  })
}
export const getUser = (data) => {
    return axios.request({
        url:'/api/user/get_by_id',
        data,
        method:'post'
    })
}

export const getToken = (data) => {
    return axios.request({
        url:'/api/get/token',
        data,
        method:'post'
    })
}
export const getBookList = (data) => {
    return axios.request({
        url:'/api/book/list',
        data,
        method:'post'
    })
}
export const getBookListByUser = (data) => {
    return axios.request({
        url:'/api/book/list_by_user',
        data,
        method:'post'
    })
}

export const getBookById = (data) => {
    return axios.request({
        url:'/api/book/get_by_id',
        data,
        method:'post'
    })
}
export const createBook = (data) => {
    return axios.request({
        url:'/api/book/create',
        data,
        method:'post'
    })
}

export const updateBook = (data) => {
  return axios.request({
      url:'/api/book/update_by_id',
      data,
      method:'post'
  })
}

export const createBookDetail = (data) => {
    return axios.request({
        url:'/api/book_detail/create',
        data,
        method:'post'
    })
}
export const updateBookDetail = (data) => {
    return axios.request({
        url:'/api/book_detail/update_by_id',
        data,
        method:'post'
    })
}
export const deleteBookDetail = (data) => {
    return axios.request({
        url:'/api/book_detail/delete_by_id',
        data,
        method:'post'
    })
}
export const getBookDetailList = (data) => {
    return axios.request({
        url:'/api/book_detail/get_by_condition',
        data,
        method:'post'
    })
}
export const rewardCreate = (data) => {
    return axios.request({
        url:'/api/reward/create',
        data,
        method:'post'
    })
}

export const uploadFile = data => {
  return axios.request({
      data,
      url:'/api/upload',
      method:'post',
      headers:{'Content-Type':'multipart/form-data'}
  })
}

export const userReceiveReport = (data) => {
  return axios.request({
      url:'/api/activity/user_receive_report',
      data,
      method:'post'
  })
}
export const receiveReport = (data) => {
  return axios.request({
      url:'/api/activity/receive_report',
      data,
      method:'post'
  })
}
export const receiveReport2 = (data) => {
  return axios.request({
      url:'/api/activity/receive_report2',
      data,
      method:'post'
  })
}
export const receiveInvite = (data) => {
  return axios.request({
      url:'/api/activity/receive',
      data,
      method:'post'
  })
}
export const receive2 = (data) => {
  return axios.request({
      url:'/api/activity/receive2',
      data,
      method:'post'
  })
}
export const getAirdropStage = (data) => {
  return axios.request({
      url:'/api/activity/get_airdrop_stage',
      data,
      method:'post'
  })
}
export const postAirdrop = (data) => {
  return axios.request({
      url:'/api/activity/airdrop',
      data,
      method:'post'
  })
}
export const getAirdropAvailable = (data) => {
  return axios.request({
      url:'/api/activity/get_airdrop_available',
      data,
      method:'post'
  })
}


