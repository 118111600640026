import { useState, useEffect } from 'react'
import { getBookById } from '@/data';

import { isMock, mockBookList } from '@/mock'
export default function useBookInfo({ bookId }) {
    const [bookInfo, setBookInfo] = useState()
    useEffect(() => {
        loadBookInfo()
    }, [bookId])
    const loadBookInfo = async () => {
        if (!bookId) return;
        let params = {
            "author": "",
            "id": +bookId,
            "limit": 0,
            "offset": 0,
            "title": ""
        }
        const mockBook = {
            code: 200,
            data: {}
        }
        if (isMock) {
            mockBook.data = mockBookList.data.list.find(i => +i.id === +bookId)
        }
        const res = isMock ? mockBook : await getBookById(params)
        // const res = {
        //     code: 200,
        //     data: mockBookList.find(i=>+i.id === +bookId)
        // }
        if (res?.code === 200) {
            setBookInfo(res.data)
        }
    }
    return bookInfo
}