import './index.scss'
import ClearDiv from '@/components/clearDiv'
import { Button, Image } from 'react-bootstrap'
import NovelChapterPop from '@/components/novelChapterPop'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setUserAmount, setWarnContent } from '@/store'
import { groupBy } from '@/utils'
import { getBookDetailList, rewardCreate } from '@/data'
import ClipboardJS from 'clipboard'
import { createAlert } from '@/components/dzAlert'
import DzPop from '@/components/dzPop'
import coin from '@/static/images/coin.png'
import que from '@/static/images/que.png'
import Cookies from 'js-cookie';
function Read() {
  const [search] = useSearchParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { address } = useSelector(state => state.home)
  const { oneNft } = useSelector(state => state.home)
  const { catwomanNft } = useSelector(state => state.home)
  const [bookId, setBookId] = useState()
  const [title, setTitle] = useState()
  const [chapter, setChapter] = useState()
  const [volumeList, setVolumeList] = useState([])
  const [finishPopShow, setFinishPopShow] = useState()
  const [gotCoin, setGotCoin] = useState()
  const jumpTo = useNavigate()
  const { userBalance } = useSelector(state => state.home)
  const { userAmount } = useSelector(state => state.home)

  useEffect(() => {
    setBookId(search.get('id'))
    setTitle(search.get('title'))
    const clipboard = new ClipboardJS('.copy-link');
    clipboard.on('success', function (e) {
      createAlert({ type: 'success', content: 'Replicating Success!' })
      e.clearSelection();
    });
    clipboard.on('error', function (e) {
      console.error('Action:', e.action);
      console.error('Trigger:', e.trigger);
    });
  }, [])
  useEffect(() => {
    const volume = search.get('volume')
    const chapter = search.get('chapter')
    setChapter(volumeList?.find(vol => `${vol.volume}` === `${volume}`)?.list?.find(chap => `${chap.chapter}` === `${chapter}`))
  }, [location])
  useEffect(() => {
    loadBookDetailList(bookId)
    if (bookId && address) {
      setGotCoin(localStorage.getItem(`coin_${bookId}_${address}`) > 0)
    }
  }, [bookId])
  useEffect(() => {
    if (bookId && address) {
      setGotCoin(localStorage.getItem(`coin_${bookId}_${address}`) > 0)
    }
  }, [address])
  useEffect(() => {
    if (chapter) {
      window.addEventListener('scroll', readCurChapter)
    }
  }, [chapter])
  useEffect(() => {
    setChapter(chapter || volumeList[0]?.list?.[0])
  }, [volumeList])
  useEffect(() => {
    if (volumeList?.length && bookId) {
      const readBook = getReadChapter()
      volumeList.forEach(vol => {
        vol?.list?.forEach(chap => {
          chap.read = readBook[`book_${chap.volume}_${chap.chapter}`]
        })
      })
    }
  }, [volumeList, bookId])
  function readCurChapter() {
    if (chapter && volumeList) {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop + windowHeight === scrollHeight) {
        const readBook = JSON.parse(localStorage.getItem(`read_${address}_${bookId}`) || '{}')
        readBook[`book_${chapter.volume}_${chapter.chapter}`] = true;
        localStorage.setItem(`read_${address}_${bookId}`, JSON.stringify(readBook))
        volumeList.forEach(vol => {
          vol?.list?.forEach(chap => {
            if (chap.volume === chapter.volume && chap.chapter === chapter.chapter) {
              chap.read = true
            }
          })
        })
        setVolumeList([...volumeList])
      }
    }
  }
  async function loadBookDetailList(bookId) {
    if (!bookId) return;
    const res = await getBookDetailList({
      "book_id": +bookId,
      "limit": 99999999,
      "offset": 0
    })
    if (res?.code === 200) {
      const groupBooksRes = groupBy(res.data, 'volume', 'volume_title')
      setVolumeList(groupBooksRes)
    }
  }
  function readChapter(e) {
    window.removeEventListener('scroll', readCurChapter)
    jumpTo(`/read?id=${e.book_id}&title=${title}&volume=${e.volume}&chapter=${e.chapter}`)
  }
  function getReadChapter() {
    return JSON.parse(localStorage.getItem(`read_${address}_${bookId}`) || '{}')
  }
  function nextChapter() {
    const readBook = JSON.parse(localStorage.getItem(`read_${address}_${bookId}`) || '{}')
    readBook[`book_${chapter.volume}_${chapter.chapter}`] = true;
    localStorage.setItem(`read_${address}_${bookId}`, JSON.stringify(readBook))
    volumeList.forEach(vol => {
      vol?.list?.forEach(chap => {
        if (chap.volume === chapter.volume && chap.chapter === chapter.chapter) {
          chap.read = true
        }
      })
    })
    // setVolumeList([...volumeList])
    let next = {};
    volumeList.forEach((vol, index) => {
      vol?.list?.forEach((chap, idx) => {
        if (chap.volume === chapter.volume && chap.chapter === chapter.chapter) {
          if (vol.list.length > idx + 1) {
            next = vol.list[idx + 1]
          }
          else {
            next = volumeList[index + 1]?.list?.[0]
          }
        }
      })
    })
    if (next) {
      readChapter(next)
    }
    else {
      dispatch(setWarnContent('No Chapter！'))
    }
  }
  function finishRead() {
    setFinishPopShow(true)
  }
  function closePop() {
    setFinishPopShow(false)
  }
  const getAmountByBalance = balance => {
    console.log('getAmountByBalance:::',balance)
    let amount = 0;
    if(+balance >= 3000){
      amount = 60;
    }
    if(+balance < 3000){
      amount = 20;
    }
    if(+balance < 500){
      amount = 10;
    }
    if(+balance < 200){
      amount = 3;
    }
    if(+balance < 50){
      amount = 1;
    }
    if(+balance < 9){
      amount = 0;
    }
    return amount
  }
  async function takeCoin() {
    console.log(oneNft)
    console.log(catwomanNft)
    console.log(userBalance)
    // if(!oneNft&&!catwomanNft?.length){
    //   dispatch(setWarnContent('Sorry you have no R2E reward, only users hold ONE pass card or catwomen NFT can get $16DAO tokens.'))
    //   return;
    // }

    let params = {
      hold_amount: +`${getAmountByBalance(userBalance)}`,
      hold_balance: +`${userBalance}`,
      book_id: +bookId,
      token_address: address
    }
    let res = await rewardCreate(params)
    closePop()
    console.log(res)
    if (res?.code === 200) {
      createAlert({ type: 'success', content: 'Success!' })
      let expires = new Date(new Date() * 1 + 24 * 60 * 60 * 1000)
      Cookies.set('amount', +getAmountByBalance(userBalance)+ +userAmount, { expires })
      dispatch(setUserAmount(+getAmountByBalance(userBalance)+ +userAmount))
    }
    if (res?.code === 401) {
      createAlert({ type: 'danger', content: res.msg })
    }
    if (res?.code === 200 || res?.code === 401) {
      setGotCoin(true)
      localStorage.setItem(`coin_${bookId}_${address}`, `${getAmountByBalance(userBalance)}`)
    }
  }
  return (
    <>
      <div className='container read'>
        <ClearDiv>
          <div className='read-title flex-row-align-end flex-row-space-between'>
            <div>Novel Name：<span>{title}</span></div>
            <Button variant="outline-secondary" className='copy-link' data-clipboard-text={decodeURI(window.location.href)}>Copy Link</Button>
          </div>
          <div className='read-main'>
            <div className='read-chapter'>
              Chapter {chapter?.chapter} · {chapter?.chapter_title}
            </div>
            <div className='read-content'>
              {chapter?.content}
            </div>
          </div>
        </ClearDiv>
        <div className='read-cost flex-row-center'>
          {/* <div className='cost-info'>
            <div className='info-item flex-row-space-between'>
              <div className='info-tag'>ARWEAVE TX</div>
              <div className='text-line-1'>R6Lezh9CLj4B5Qf6QxDN_W-gAWHPNpiWhYrPfFkGc88</div>
            </div>
            <div className='info-item flex-row-space-between'>
              <div className='info-tag'>OPTIMISM ADDRESS</div>
              <div className='text-line-1'>{address}</div>
            </div>
          </div> */}
          {volumeList.filter(vol => vol?.list?.filter(chap => !chap.read).length).length ? <Button onClick={e => nextChapter()}>Next Chapter</Button> : <Button onClick={e => finishRead()}>Finish</Button>}
        </div>
      </div>
      <NovelChapterPop volumeList={volumeList} bookId={bookId} chapterClick={e => readChapter(e)}></NovelChapterPop>
      <DzPop popShow={finishPopShow} closeClick={e => closePop()} >
        {/* wirter got coin  */}
        {/* <div className='m-g-b-30 p-d-t-10 p-d-l-10 p-d-r-10'>Writers can't be rewarded for reading their own novels. Read other novels can reward.</div> */}
        <div className='reader-take'>
          <div className='desc'>
            {gotCoin ?
              'You have already read the novel and received a token reward. You cannot receive an additional reward when you read it again.' :
              <div>
                <div className='m-g-b-20 desc'>Congratulations on the completion of reading this novel. A good novel improves your cognition and makes you experience different lives and different wonderful things. Congratulations on getting the reward of reading tokens.
                </div>
                <div>The reward tokens for reading will be distributed to the wallet on the last day of each month.</div>
              </div>
            }
          </div>
          {gotCoin ? <></> :
            <div className='m-g-l-10 m-g-r-10'>
              <div>Number Of Your Holding:  {userBalance} </div>
              <div>Rewards Per Novel: </div>
            </div>
          }
          <div className='coin'>
            {/* <Image src={coin} width={64} className="coin-img"></Image> */}
            <div className='flex-row-center flex-row-align-baseline'>
              <span className='num heavy-title'>{getAmountByBalance(userBalance)}</span>
              {/* <span className='tag heavy-title m-g-l-4 m-g-r-4'>$16DAO</span> */}
              {/* <Image src={que} width={12}></Image> */}
            </div>
            {/* <div className='usd heavy-title'>≈294,852 USD</div> */}
          </div>
          <div className='take-btn'>
            {gotCoin ? `Rewards you get for your first reading.` : <Button className='flex-row-center' onClick={e => takeCoin()}>Got it</Button>}
          </div>
        </div>
      </DzPop>
    </>
  )
}
export default Read