import './index.scss'
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import novel1 from '@/static/images/novel1.png'
import novel2 from '@/static/images/novel2.png'
import novel3 from '@/static/images/novel3.png'
import novel4 from '@/static/images/novel4.png'
import novel5 from '@/static/images/novel5.png'
import coverErr from '@/static/images/coverErr.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import { getBookList } from '@/data';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoadingCoverStatus } from '@/store';
function BookList() {
  const winWidth = window.innerWidth
  const num = Math.floor(winWidth / 160) > 5 ? 5 : Math.floor(winWidth / 160) < 3 ? 3 : Math.floor(winWidth / 160)
  let jumpTo = useNavigate()
  const dispatch = useDispatch()
  const [bookList, setBookList] = useState([])
  const [size, setSize] = useState(num)
  useEffect(() => {
    loadBookList()
    window.onresize = e => {
      const winWidth = window.innerWidth
      const num = Math.floor(winWidth / 160) > 5 ? 5 : Math.floor(winWidth / 160) < 3 ? 3 : Math.floor(winWidth / 160)
      setSize(num)
    }
  }, [])

  async function loadBookList() {

    dispatch(setLoadingCoverStatus(true))
    let res = await getBookList({
      "page_size": 99999999,
      "page_no": 1,
      "title": "",
      "status":4
    });
    console.log(res)
    dispatch(setLoadingCoverStatus(false))
    if (res?.code === 200) {
      const { data } = res;
      const { list } = data
      setBookList(list || [])
    }
  }
  return (
    <div className='book-list'>
      {/* <Swiper spaceBetween={50}
        slidesPerView={size}
        onSlideChange={() => false}
        onSwiper={(swiper) => false}> */}
        {bookList.map(book => {
          // return <SwiperSlide key={book.id}>
          return <div className='book-item' key={book.id}><img  onClick={e => jumpTo(`/book?id=${book.id}`)} title={book.title} src={book.image_url || coverErr} width={190} onError={e => e.target.src = coverErr} /></div>
          {/* </SwiperSlide> */ }
        })}
      {/* </Swiper> */}
    </div>
  )
}
export default BookList