import './index.scss'
import TitleDiv from '@/components/titleDiv'
import { Table } from 'react-bootstrap';
function assignmentDetail() {
  return (
    <div className='assignment-detail'>
      <TitleDiv>Assignment detail</TitleDiv>
      <Table>
        <thead>
          <tr>
            <th>SECTION</th>
            <th>DISTRIBUTION</th>
            <th>TOKEN AMOUNT</th>
            <th className='text-center'>%</th>
            <th>VESTING</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Community</td>
            <td>
              <div>Aridrop to Catwomen NFTs’ holders</div>
              <div>Aridrop to ONE pass card’s holders</div>
              <div>Private crowdfunding</div>
              <div>Community and float on exchange</div>
            </td>
            <td className='text-center'>
              <div>80,000</div>
              <div>700,000</div>
              <div>700,000</div>
              <div>8,120,000</div>
            </td>
            <td className='text-center'>
              <div>0.5%</div>
              <div>4.375%</div>
              <div>4.375%</div>
              <div>50.75%</div>
            </td>
            <td>
              <div>Unlock</div>
              <div>Unlock</div>
              <div>Unlock</div>
              <div>Unlock</div>
            </td>
          </tr>
          <tr>
            <td>DZ DAO</td>
            <td>
              <div>First batch of novels were signed</div>
              <div>Awards for NFT artists</div>
              <div>Awards for novel writers</div>
            </td>
            <td className='text-center'>
              <div>600,000</div>
              <div>300,000</div>
              <div>1,180,000</div>
            </td>
            <td className='text-center'>
              <div>3.75%</div>
              <div>1.875%</div>
              <div>7.375%</div>
            </td>
            <td>
              <div>Unlock</div>
              <div>Unlock</div>
              <div>Unlock</div>
            </td>
          </tr>
          <tr>
            <td>Contributor</td>
            <td>
              <div>Investor</div>
              <div>Official website development</div>
              <div>Awards for official website online</div>
              <div>CRM system development</div>
              <div>Awards for CRM system online</div>
              <div>Smart contract development</div>
              <div>Awards for smart contract online</div>
              <div>Product function planning</div>
              <div>Branding and UI design</div>
              <div>Twitter promotion / BD</div>
            </td>
            <td className='text-center'>
              <div>1,000,000</div>
              <div>250,000</div>
              <div>50,000</div>
              <div>400,000</div>
              <div>100,000</div>
              <div>800,000</div>
              <div>200,000</div>
              <div>200,000</div>
              <div>100,000</div>
              <div>100,000</div>
            </td>
            <td className='text-center'>
              <div>6.25%</div>
              <div>1.5625%</div>
              <div>0.3125%</div>
              <div>2.5%</div>
              <div>0.625%</div>
              <div>5%</div>
              <div>1.25%</div>
              <div>1.25%</div>
              <div>0.625%</div>
              <div>0.625%</div>
            </td>
            <td>
              <div>12 months cliff + Linearly over 10 months</div>
              <div>10 months cliff + Linearly over 10 months</div>
              <div>Unlock</div>
              <div>10 months cliff + Linearly over 10 months</div>
              <div>Unlock</div>
              <div>10 months cliff + Linearly over 10 months</div>
              <div>Unlock</div>
              <div>10 months cliff + Linearly over 10 months</div>
              <div>10 months cliff + Linearly over 10 months</div>
              <div>10 months cliff + Linearly over 10 months</div>
            </td>
          </tr>
          <tr>
            <td>Team</td>
            <td>
              <div>Founder & CEO</div>
              <div className='total-td'>Blockchain: Ethereum</div>
            </td>
            <td className='text-center'>
              <div>1,120,000</div>
              <div className='total-td'>Supply: 16,000,000</div>
            </td>
            <td className='text-center'>
              <div>7%</div>
              <div className='total-td'>100%</div>
            </td>
            <td>
              <div>18 months cliff + Linearly over 25 months</div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
export default assignmentDetail;