import './index.scss'
import { Image } from 'react-bootstrap'
import coin from '@/static/images/coin.png'
import TokenCard from './components/tokenCard'
import AssignmentDetail from './components/assignmentDetail'
function Token(){
  const cardDataList = [
    {index:"01",title:"Number one position",desc:"When the number of $16DAO held exceeds a certain limit, holder has opportunity to become the CEO of a business of DZ Labs, such as taking full charge of operation of the foundation, responsible for the development of wallet business and so on. In fact, this is what we expect to happen. While enjoying the right of governance, the holder will get high returns along with the development of the business."},
    {index:"02",title:"Multi Token airdrop",desc:"DZ Labs will develop multiple commercial activities based on holding novels, novels will be transformed into Gamefi, they will have a separate token economy, and holders of $16DAO will be able to get airdrop from those GameFi every time. When novel is adapted into a movie, holders of $16DAO will also be eligible for a stablecoin aridrop from the movie's box office."},
    {index:"03",title:"Repurchase income",desc:"When deploying the token contract, DZ Labs will distribute more than 80% of the tokens to the community in various ways. However, in the following fiscal years, DZ Labs will allocate the profit from fiscal revenue to the trading market to repurchase $16DAO tokens. Treasury will use the repurchased tokens to develop more economic models, which will make DZ Labs more valuable and make higher returns for token holders."},
  ]
  return (
    <div className='token-view container'>
      <div className="token-top text-center">
        <Image src={coin} className='m-g-b-40' width={210}></Image>
        <div className='heavy-title token-title m-g-10'>$16DAO Token Metric</div>
        <div className='token-desc m-g-b-30'>16dao.xyz is one product of DZ Labs. <span>DZ</span> comes from the first letter of <span>D</span>ecentralization <span>Z</span>one. Our vision is to build a decentralized organization so that individual wisdom and value will be enlarged. So we designed $16DAO Token, which will be the blood of DZ Labs. We will gradually open the participation rights of the organization to token holders. The token is equivalent to their owning shares of DZ Labs, enjoying voting rights and dividend rights. They're going to pro rata back to the token holder.</div>
      </div>
      <div className='flex-row-space-around card-list'>
        {cardDataList.map(card=><TokenCard key={card.index} index={card.index} title={card.title} desc={card.desc}/>)}
      </div>
      <AssignmentDetail />
    </div>
  )
}
export default Token