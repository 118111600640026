import './index.scss'
import { Tabs,Tab } from 'react-bootstrap';
import Airdrop1 from './components/airdrop1';
import Airdrop2 from './components/airdrop2';
import Airdrop3 from './components/airdrop3';
// import Claim from './components/claim';
import Claim2 from './components/claim2';
import Claim3 from './components/claim3';
// import Rules from './components/rules'
// import Rules2 from './components/rules2'
function Airdrop (){
  return (
    <div className='container airdrop'>
      {/* 一期空投 */}
      {/* <Claim></Claim>
      <Rules></Rules> */}

      {/* 二期空投 */}
      {/* <Claim2></Claim2> */}
      {/* <Rules2></Rules2> */}
      {/* 三期空投 */}
      <Claim3></Claim3>
    </div>  
  )
}
// function Airdrop(){
//     return (
//         <div className='container airdrop'>
//             <Tabs
//                 defaultActiveKey="a1"
//                 className=""
//                 >
//                 <Tab eventKey="a1" title="Airdrop 1">
//                     <Airdrop1 />
//                 </Tab>
//                 <Tab eventKey="a2" title="Airdrop 2">
//                     <Airdrop2 />
//                 </Tab>
//                 <Tab eventKey="a3" title="Airdrop 3" >
//                     <Airdrop3 />
//                 </Tab>
//             </Tabs>
//         </div>
//     )
// }
export default Airdrop;