import { useEffect } from 'react'
import './index.scss'
function BookChapter({ bookChapter }) {
    useEffect(() => {
        console.log('bookChapter:', bookChapter)
    }, [])
    return (
        <div className='bookChapter'>
            <div>
                <div className='enjoy-now'>Enjoy the Novel Now</div>
                {
                    bookChapter?.map(i =>
                        <div key={`${i.book_id}-${i.volume}`}>
                            <div className='volume'>[ Volume {i.volume} : {i.name} ]</div>
                            {
                                i.list.map(chapter =>
                                    <div className='chapter' key={chapter.chapter} id={`${chapter?.book_id}-${chapter?.volume}-${chapter?.chapter}`}>
                                        <div className='chapter-title' >
                                            Chapter {chapter.chapter} : {chapter.chapter_title}
                                        </div>
                                        <div className='content' style={{whiteSpace:'pre-wrap'}} dangerouslySetInnerHTML={{__html:chapter.content.replace(/\\n/g,'<br />')}}></div>
                                    </div>)
                            }
                        </div>)
                }

            </div>
        </div>
    )
}
export default BookChapter