import './index.scss'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
function Loading() {

  // const { loading } = useSelector(state => state.home)
  const { loading } = {loading:true}
  useEffect(()=>{
    console.log(loading)
  })
  return (
    <>
      {loading ?
        <div className='loading flex-row-center'>
          <div className='load'>
            <div>L</div>
            <div>o</div>
            <div>a</div>
            <div>d</div>
            <div>i</div>
            <div>n</div>
            <div>g</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
          </div>
        </div> : ''}
    </>
  )
}
export default Loading;