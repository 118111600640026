import './index.scss'
import ClearDiv from '@/components/clearDiv'
import { Button, Image } from 'react-bootstrap'
import deleteIcon from '@/static/images/delete.png'
import editIcon from '@/static/images/edit.png'
import DzPop from '@/components/dzPop'
import { getBookById, getBookDetailList, createBookDetail, updateBookDetail, deleteBookDetail } from '@/data'
import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import FormInput from '@/components/formInput'
import FormTextArea from '@/components/formTextArea'
import { useSelector, useDispatch } from 'react-redux'
import { setLoadingCoverStatus } from '@/store'
import { groupBy } from '@/utils'
function Volume() {
  const volumeText = {
    createVolume: 'Create',
    editVolume: 'Edit'
  }
  const dispatch = useDispatch();
  const jumpTo = useNavigate();
  const [bookId, setBookId] = useState()
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [author, setAuthor] = useState()
  const [search] = useSearchParams()
  const [createVolumePopShow, setCreateVolumePopShow] = useState()
  const [volumeName, setVolumeName] = useState()
  const [chapterName, setChapterName] = useState()
  const [content, setContent] = useState()
  const [deleteChapterPopShow, setDeleteChapterPopShow] = useState()
  const [deleteVolumePopShow, setDeleteVolumePopShow] = useState()
  const [popType, setPopType] = useState()
  const [volume, setVolume] = useState()
  const [chapter, setChapter] = useState()
  const [volumeList, setVolumeList] = useState([])
  const [chapterStatus, setChapterStatus] = useState(false)
  useEffect(() => {
    setBookId(search.get('bookId'))
  }, [])
  useEffect(() => {
    initPage()
  }, [bookId])
  useEffect(() => {
  }, [volumeList])
  useEffect(() => {
  }, [volume])
  useEffect(() => {
  }, [chapter])
  useEffect(() => {
    if (!chapterStatus) {
      setChapterName('')
      setContent('')
      setPopType('')
    }
  }, [chapterStatus])
  useEffect(() => {
    console.log(chapter)
    if (!createVolumePopShow && !createVolumePopShow && !createVolumePopShow) {
      // setVolume('')
      // setChapter('')
      setPopType('')
    }
    console.log(chapter)
  }, [createVolumePopShow, deleteChapterPopShow, deleteChapterPopShow, deleteVolumePopShow])
  useEffect(() => {
    if (['createVolume', 'editVolume', 'deleteVolume', 'deleteChapter'].includes(popType)) {
      if (popType === 'editVolume' && volume) {
        setVolumeName(volume.volume_title)
      }
      openPop(popType)
    }
    if (['createChapter', 'editChapter'].includes(popType)) {
      setChapterName(chapter?.chapter_title || '')
      setContent(chapter?.content || '')
      setChapterStatus(true)
    }
  }, [popType])
  function setPopTypeVal(type, volume, chapter) {
    console.log(chapter)
    setPopType(type)
    setVolume(volume)
    setChapter(chapter)
  }
  async function loadBookInfo(bookId) {
    if (!bookId) return;
    let params = {
      "author": "",
      "id": +bookId,
      "limit": 0,
      "offset": 0,
      "title": ""
    }
    let res = await getBookById(params)
    if (res?.code == 200) {
      const { author_name, description, image_url, title } = res.data
      setTitle(title)
      setDescription(description)
      setAuthor(author_name)
    }
  }
  async function loadBookDetailList(bookId) {
    if (!bookId) return;
    const res = await getBookDetailList({
      "book_id": +bookId,
      "limit": 99999999,
      "offset": 0
    })
    if (res?.code === 200) {
      const groupBooksRes = groupBy(res.data, 'volume', 'volume_title')
      setVolumeList(groupBooksRes)
    }
  }
  async function submitVolumeChapter() {
    let query = {};
    let res = {}
    if (['createVolume'].includes(popType)) {
      query = {
        "book_id": +bookId,
        "chapter": 1,
        "chapter_description": "",
        "chapter_title": '',
        "content": '',
        "volume_description": "",
        "volume": volumeList?.length + 1,
        "volume_title": volumeName
      }

      res = await createBookDetail(query)
    }
    if (['createChapter'].includes(popType)) {
      query = {
        "book_id": +bookId,
        "chapter": volume?.chapter + 1,
        "chapter_description": "",
        "chapter_title": chapterName,
        "content": content,
        "volume_description": "",
        "volume": volume?.volume,
        "volume_title": volume.volume_title
      }
      res = await createBookDetail(query)
    }
    if (['editVolume'].includes(popType)) {
      let updateVolume = volumeList.find(vol => vol.volume === volume.volume)?.list?.[0]
      query = {
        "id": updateVolume?.id,
        "book_id": +bookId,
        "chapter": updateVolume?.chapter,
        "chapter_description": updateVolume.chapter_description,
        "chapter_title": updateVolume.chapter_title,
        "content": updateVolume.content,
        "volume_description": updateVolume.volume_description,
        "volume": updateVolume.volume,
        "volume_title": volumeName
      }
      res = await updateBookDetail(query)
    }
    if (['editChapter'].includes(popType)) {
      query = {
        "id": chapter?.id,
        "book_id": +bookId,
        "chapter": chapter?.chapter,
        "chapter_description": "",
        "chapter_title": chapterName,
        "content": content,
        "volume_description": "",
        "volume": chapter?.volume,
        "volume_title": volume.volume_title
      }
      res = await updateBookDetail(query)
    }
    console.log(query)
    console.log(volume)
    if (res?.code === 200) {
      initPage()
    }
  }
  async function initPage() {
    setChapterStatus(false)
    closePop()
    loadBookInfo(bookId)
    loadBookDetailList(bookId)
  }
  async function submitDeleteChapter() {
    console.log(chapter)
    console.log(volume)
    if (!chapter) return;
    let res = await deleteBookDetail(chapter)
    if (res?.code === 200) {
      initPage()
    }
  }
  async function submitDeleteVolume() {
    if (!volume) return;
    console.log(volumeList)
    console.log(volume)
    const delVolList = volumeList.find(vol => vol.volume === volume.volume)?.list.map(async chapter => {
      return await deleteBookDetail(chapter)
    })
    Promise.all(delVolList).then(resList => {
      let allSuccess = resList.filter(res => res?.code === 200).length === resList.length
      if (allSuccess) {
        initPage()
      }
    }).catch(err => { })
  }

  function openPop(type) {
    setCreateVolumePopShow(['createVolume', 'editVolume'].includes(type))
    setDeleteVolumePopShow(type === "deleteVolume")
    setDeleteChapterPopShow(type === "deleteChapter")
  }
  function closePop() {
    setCreateVolumePopShow(false)
    setDeleteVolumePopShow(false)
    setDeleteChapterPopShow(false)
    setVolumeName('')
  }
  return (
    <div className='volume-view container'>
      <ClearDiv>
        <div className='volume-header'>
          <div className='volume-title'>{title}</div>
          <div className='volume-desc'>{author}  |  {description}</div>
          <Button style={{ display: chapterStatus ? 'none' : 'block' }} variant="outline-secondary" onClick={e => setPopTypeVal('createVolume')}>Create Volume</Button>
        </div>
        <div className='volume-content'>
          {volumeList.map((vol, idx) => {
            return <div style={{ display: chapterStatus ? 'none' : 'block' }} className=' volume-item' key={`vol-${idx}`}>
              <div className='flex-row-space-between volume-item'>
                <div className='volume-item-title text-line-1'>Volume {vol.volume} : {vol.name}</div>
                <div className='flex-row-space-around'>
                  <Button variant="outline-secondary" onClick={e => setPopTypeVal('editVolume', vol?.list?.[0])}>Edit Volume</Button>
                  <Button variant="outline-secondary" onClick={e => setPopTypeVal('createChapter', vol?.list?.[vol?.list?.length - 1])}>Create Chapter</Button>
                  <Button variant="outline-secondary" onClick={e => setPopTypeVal('deleteVolume', vol?.list?.[0])}>Delete</Button>
                </div>
              </div>
              {vol.list.map((chapter, idx) => {
                return <div className='flex-row-space-between chapter-item' key={`chapter-${idx}`}>
                  <div className='chapter-item-title'>Chapter {chapter?.chapter} : {chapter?.chapter_title}</div>
                  <div className='flex-row-space-around'>
                    <Image src={editIcon} width={28} onClick={e => setPopTypeVal('editChapter', vol?.list?.[0], chapter)} />
                    <Image src={deleteIcon} width={28} onClick={e => setPopTypeVal('deleteChapter', vol?.list?.[0], chapter)} />
                  </div>
                </div>
              })}
            </div>
          })}
          {/* <div style={{ display: chapterStatus ? 'block' : 'none' }} className='flex-row-space-between volume-item'>
            <div className='volume-item-title'>Volume {volume?.volume} : {volume?.volume_title}</div>
            <div className='active-chapter'>
              <div className='chapter-item-title'>
                <FormInput style={{width:'100%',margin:'0 0 20px'}} onInput={e=>setChapterName(e)} placeholder={`Chapter ${chapter?.chapter||volume?.chapter+1}:`} defaultValue={popType === 'createChapter'?'':chapterName}></FormInput>  
                <FormTextArea onInput={e=>setContent(e)} placeholder="Do not write headings in the body content, please limit to 30000 words." maxLength={30000} defaultValue={popType==='createChapter'?'':content}></FormTextArea>
              </div>
            </div>
          </div> */}
          {chapterStatus ? <div className='flex-row-space-between volume-item' style={{ display: 'block' }}>
            <div className='volume-item-title text-line-1'>Volume {volume?.volume} : {volume?.volume_title}</div>
            <div className='active-chapter'>
              <div className='chapter-item-title'>
                <FormInput style={{ width: '100%', margin: '0 0 20px' }} onInput={e => setChapterName(e)} placeholder={`Chapter ${chapter?.chapter || volume?.chapter + 1}:`} defaultValue={chapterName} maxLength={50}></FormInput>
                <FormTextArea onInput={e => setContent(e)} placeholder="Do not write headings in the body content, please limit to 100000 words." maxLength={100000} defaultValue={content}></FormTextArea>
              </div>
            </div>
          </div> : ''}
        </div>
        <div style={{ display: chapterStatus ? 'flex' : 'none' }} className="flex-row" >
          <Button onClick={e => submitVolumeChapter()}>Publish</Button>
          <Button className='cancel-btn' variant="outline-secondary" onClick={e => setChapterStatus(false)}>Cancel</Button>
        </div>
        {/* {chapterStatus?<div className="flex-row" >
          <Button onClick={e=>submitVolumeChapter()}>Publish</Button>
          <Button variant="outline-secondary" onClick={e => setChapterStatus(false)}>Cancel</Button>
        </div>:''} */}
      </ClearDiv>
      <DzPop popShow={createVolumePopShow} closeClick={e => closePop()} >
        <div className='pop-content'>{volumeText[popType]} Volume</div>
        <FormInput dark defaultValue={volumeName} placeholder={'Volume title less than 50 words!'} maxLength={50} onInput={e => setVolumeName(e)}></FormInput>
        <Button className='pop-btn' onClick={e => submitVolumeChapter()}>Save</Button>
      </DzPop>
      <DzPop popShow={deleteChapterPopShow} closeClick={e => closePop()} >
        <div className='pop-content'>You're deleting Chapter. Are you sure?</div>
        <Button className='pop-btn' onClick={e => submitDeleteChapter()}>Sure</Button>
      </DzPop>
      <DzPop popShow={deleteVolumePopShow} closeClick={e => closePop()} >
        <div className='pop-content'>When the Volume is deleted, the content of the following chapters will be deleted. Are you sure?</div>
        <Button className='pop-btn' onClick={e => submitDeleteVolume()}>Sure</Button>
      </DzPop>
    </div>
  )
}
export default Volume