import './index.scss'
import { Button, Image } from 'react-bootstrap';
import metamaskIcon from '@/static/images/SB-mt.png'
import ClearDiv from '@/components/clearDiv';
import { useSelector } from 'react-redux';
function Airdrop3() {
  const { address } = useSelector(state => state.home)
  return (
    <div className='airdrop-3'>
      <div className='title'>This round size is 700,000 tokens,  there are 2,956 left to claim. After the delivery of the event will be over.You are treated as an early investor in mint, which allows you to buy tokens early at a lower price, and to share benefit with the project as soon as it listes .</div>
      <div className="tip">Tip: Only mint once per user.</div>
      <div className="mint flex-row">
        <div className="mint-num">0.008 = 200 16DAO Toke</div>
        <div className='mint-btn'>
          <Button className='mint-button flex-row-center'>Mint</Button>
        </div>
      </div>
      <div className="contract">Ethereum Contract : {address} <Image src={metamaskIcon} className="m-g-l-10" width={16}></Image></div>
      <ClearDiv className="minted">
        <div className='minted-title'>Who had minted</div>
        <div className='minted-list'>
          <div className="minted-header flex-row-space-between">
            <div>Wallet</div>
            <div>Price</div>
            <div>Count</div>
          </div>
          <div className="minted-item flex-row-space-between">
            <div className='text-line-1'>0x2E6*********D06A</div>
            <div className='price'>0.008 ETH</div>
            <div>200</div>
          </div>
          <div className="minted-item flex-row-space-between">
            <div className='text-line-1'>0x2E6*********D06A</div>
            <div className='price'>0.008 ETH</div>
            <div>200</div>
          </div>
          <div className="minted-item flex-row-space-between">
            <div className='text-line-1'>0x2E6*********D06A</div>
            <div className='price'>0.008 ETH</div>
            <div>200</div>
          </div>
        </div>
      </ClearDiv>
    </div>
  )
}
export default Airdrop3;