import './index.scss';
import coinGif from '@/static/images/coin.gif'
import Image from 'react-bootstrap/Image'
import logo from '@/static/images/logo.png'
import coin from '@/static/images/coin.png'
import que from '@/static/images/que.png'
import logout from '@/static/images/logout.png'
import logoutgray from '@/static/images/logoutgray.png'
import gift from '@/static/images/gift.png'
import rewardTable from '@/static/images/rewardTable.png'

import metamask from '@/static/images/SB-mt.png'
import nftpost from '@/static/images/nftpost.png'
import Container from 'react-bootstrap/Container';
import { Form } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import { Button, CloseButton, Alert, Navbar, Popover, Overlay, Toast, ToastContainer } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { useNavigate, useLocation } from 'react-router-dom';
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { getNonce, login, createUser, getToken, getUser } from '@/data';
import { createLoading, removeLoading } from '@/data/axios';
import { useDispatch } from 'react-redux';
import { setLoadingCoverStatus, setAddress, setWarnContent, setShowWarn, setWeb3, setCatwomanNft, setOneNft,setUserBalance, setUserAmount,setAccountPopShow } from '@/store';
import { useSelector } from 'react-redux';
import { Network, Alchemy } from 'alchemy-sdk';
import Cookies from 'js-cookie';
import { eventEmitter } from '@/store/eventEmitter.js'
import sixteenDao from '@/abi/SixteenDao.json'
import { abiAddress } from '@/abi/address';
function NavList() {
  const navDataList = [
    // { label: "Home", path: '/home', },
    // { label: "R2E-$16DAO", path: '/r2e' },
    // { label: "Token", path: '/token' },
    // { label: "Airdrop", path: '/airdrop' },
    // { label: "NFT", src: 'https://opensea.io/collection/catwomanstar' },
    // { label: "WhitePaper", src: 'https://medium.com/@16DAO/dz-labs-whitepaper-financing-d9f475a74d66' },
    { label: "Twitter", src: 'https://twitter.com/16DAOCoin' },
    // { label: "Discord", src: 'https://discord.gg/bn65mjMbgF' },
  ]
  const routerMap = {
    '/': 'Home',
    '/home': 'Home',
    '/r2e': 'R2E-$16DAO',
    '/airdrop': 'Airdrop',
    '/token': 'Token',
  }
  let jumpTo = useNavigate()
  const ref = useRef()
  const dispatch = useDispatch()
  const { address } = useSelector(state => state.home)
  const { warnContent } = useSelector(state => state.home)
  const { showWarn } = useSelector(state => state.home)
  const [isTopBar, setIsTopBar] = useState(false)
  // const [address, setAddress] = useState();
  const [loading, setLoading] = useState();
  // const [showWarn, setShowWarn] = useState(false);
  // const [warnContent, setWarnContent] = useState();
  // const [accountPopShow, setAccountPopShow] = useState(true);
  const [activeKey, setActiveKey] = useState('Home')
  const [walletPopShow, setWalletPopShow] = useState(false)
  const [target, setTarget] = useState(null);
  const [networkVersion, setNetworkVersion] = useState()
  const location = useLocation();
  const {userBalance} = useSelector(state=>state.home)
  const {userAmount} = useSelector(state=>state.home)
  const {accountPopShow} = useSelector(state=>state.home)
  // const eventEmitter = useContext(mainEventContext)
  useEffect(() => {
    scrolledFromTop();
    console.log('eventEmitter:', eventEmitter)
    eventEmitter.on('connectEvent', getMetamskConnect)
    loadWeb3Model();
    document.onclick = (e) => {
      dispatch(setAccountPopShow(false));
    }
    return () => {
      eventEmitter.off('connectEvent', getMetamskConnect)
    }
  }, [])
  useEffect(() => {
    console.log(location)
    console.log(routerMap[location.pathname])
    setActiveKey(routerMap[location.pathname] || '')
    console.log('address::',address)
    if(address){
      setTimeout(()=>{
        dispatch(setAccountPopShow(true));

      },200)
    }
  }, [location])
  useEffect(() => {
    console.log(address)
  })
  useEffect(() => {
    if(address){
      getNftsForOwner()
      setTimeout(()=>{
        dispatch(setAccountPopShow(true));

      },200)
    }
  }, [address])
  /**
   * 警告弹窗
   */
  useEffect(() => {
    setTimeout(() => {
      if (showWarn) {
        // setShowWarn(false)
        dispatch(setShowWarn(false))
        setTimeout(() => {
          dispatch(setWarnContent(''))
        }, 500)
      }
    }, 2000)
  }, [showWarn])
  useEffect(() => {
    if (warnContent) {
      // setShowWarn(true)
      dispatch(setShowWarn(true))
    }
  }, [warnContent])
  async function getBalance(address){
    const abi = sixteenDao.abi;
    window.contract = new window.web3.eth.Contract(abi,abiAddress)
    const balance = await getCardIndex(address)
    console.log('balancebalance:',balance)
    return balance
  }
  async function getCardIndex(address){
    return window.contract.methods.balanceOf(address).call()
    // .then(balance => {
    //   console.log(`用户 ${address} 的代币余额为: ${balance}`);
    //   handle = balance
    // })
    // .catch(error => {
    //   console.error('获取代币余额时出错:', error);
    // }).finally(res=>{
    //   console.log('获取代币finally:',res)
    // });
    // return handle
  }
  async function loadWeb3Model() {
    await loadWeb3();
    await loadBlockchainData();
    if (window.ethereum.chainId&&window.ethereum.chainId != "0x89") {
      await changeToMainnet()
    }
  }
  /**
   * 监听判断页面纵向滚动是否在顶部
   */
  function scrolledFromTop() {
    window.addEventListener('scroll', () => {
      setIsTopBar(window.pageYOffset > 0)
    })
  }
  /**
   * 已登录按钮点击事件
   * @param {*} event 
   */
  const addressClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(setAccountPopShow(!accountPopShow));
    setTarget(event.target);
  };
  /**
   * 挂载web3模块
   */
  async function loadWeb3() {
    const provider = await detectEthereumProvider();
    if (provider) {
      console.log('ethereum wallet is connected')
      window.web3 = new Web3(provider)
    } else {
      logoutWallet()
      console.log('no ethereum wallet detected')
    }
  }
  /**
   * 载入链路，获取钱包地址
   */
  async function loadBlockchainData() {
    const web3 = window.web3
    console.log('window.ethereum.chainId:', window.ethereum.chainId)
    const accounts = await web3?.eth?.getAccounts()
    if (accounts && accounts.length) {
      // to do 
      // let balance = await web3?.eth?.getBalance(accounts[0])
      // const blockNumber = await web3?.eth.getBlockNumber()
      // const block = await web3?.eth.getBlock(blockNumber)
      // const ethContract = await web3?.eth.contract('代币合约abi')
      // const contractInstance = await ethContract.at('代币合约地址')
      // const count = await contractInstance.allowance(accounts[0],'授权的地址')
      // console.log('block:',block)
      // console.log('blockNumber:',blockNumber)
      // console.log('balance:',balance)
      console.log('acconts:', accounts)
      if (Cookies.get('token')) {
        dispatch(setAddress(accounts[0].toLowerCase()))
      }
      if(Cookies.get('balance')){
        dispatch(setUserBalance(Cookies.get('balance')))
      }
      if(Cookies.get('amount')){
        dispatch(setUserAmount(Cookies.get('amount')))
      }
      chainListenEvent();
    }
    else {
      logoutWallet()
    }
  }
  async function changeToMainnet(e) {
    const res = await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: `0x89` }] })
    console.log('res:', res)
    loadWeb3Model();
  }
  /**
   * 链路监听事件
   */
  function chainListenEvent() {
    window.ethereum.once('accountsChanged', (res) => {
      logoutWallet()
      console.log('accounts Changed:', res)
    })
    window.ethereum.once('chainChanged', (res) => {
      logoutWallet()
      console.log('window.ethereum.networkVersion:', window.ethereum.networkVersion)
      console.log('network changed:', res)
    })
  }
  /**
   * 
   * @returns 是否有metaMask
   */
  function isMetaMask() {
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }
  function btnLock() {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }
  /**
   * 手动连接metaMask
   */
  async function getMetamskConnect() {
    console.log(window)
    btnLock()
    if (!isMetaMask()) {
      setWalletPopShow(!walletPopShow)
      // window.open("https://metamask.io/");
    }
    if (window.ethereum) {
      try {
        if (window.ethereum.chainId != "0x89") {
          await changeToMainnet()
        }
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts"
        });
        loginGetNonce(accounts[0])
        // let res;
        // res = await getUser({
        //   "uid": accounts[0]
        // })
        // if (res?.code === 200 && res?.data?.user_name) {
        //   setAddress(res?.data?.user_name);
        // }
        // else {
        //   setAddress(accounts[0]);
        //   res = createUser({
        //     "role": 0,
        //     "user_name": accounts[0],
        //     "uid": accounts[0]
        //   })
        // }
        // if (res?.data?.user_name) {
        //   uploadSaveToken(res.data)
        // }
      } catch (error) {

        dispatch(setWarnContent('Please authorize to access your account!'))

        console.warn("Please authorize to access your account");
      }
    }
  }
  /**
   * 获取签名nonce
   * @param {string} address 钱包地址
   */
  async function loginGetNonce(address) {
    let params = {
      "address": address,
      "nonce": "",
      "signature": "",
      "token": ""
    }
    let res = await getNonce(params)
    if (res?.code === 200) {
      const { nonce } = res.data
      wakeSignature({ address, nonce })
    }
  }
  /**
   * 获取用户签名
   * @param {string} param0 
   */
  function wakeSignature({ address, nonce }) {
    const web3 = window.web3
    const nonceHex = web3.utils.utf8ToHex(nonce);
    let balance = null;
    web3.eth.personal.sign(nonceHex, address,
      async (res, signature) => {
        createLoading()
        console.time()
        getBalance(address).then(bal=>{
          console.log('balll:::',bal)
          balance = parseInt(bal/1000000000000000000)
        }).catch(err=>{
          console.error(err)
        }).finally(res=>{
          removeLoading()
          console.timeEnd()
          console.log('final:',balance)
          let expires = new Date(new Date() * 1 + 24 * 60 * 60 * 1000)
          Cookies.set('balance', balance, { expires })
          dispatch(setUserBalance(balance))
          loginIn({ address, signature, nonce,balance })
        })
      })
  }

  const getNftsForOwner = async () => {
    console.log('Alchemy:', Alchemy)
    console.log('Network:', Network)
    const settings = {
      apiKey: "nOlHYySXosSt9h6bP8q-ZL7Pqzlqfo52",
      network: Network.ETH_MAINNET,
    };
    const alchemy = new Alchemy(settings);
    const { ownedNfts } = await alchemy.nft.getNftsForOwner(address?.toLowerCase())
    // const {ownedNfts} = await alchemy.nft.getNftsForOwner("0xd54Cf75516f91501a93cf7E2B3ba8D371dF94c1f")
    console.log(ownedNfts)
    const oneNft = ownedNfts?.find(nft => nft.contract.address === '0x2fD91E9B242b090B4F6C13273aD2573a02cD4C9F')
    const catwomanNft = ownedNfts?.filter(nft => nft.contract.address === "0x495f947276749ce646f68ac8c248420045cb7b5e")
    dispatch(setOneNft(oneNft))
    dispatch(setCatwomanNft(catwomanNft))
  }
  /**
   * 登录
   * @param {string} param0 
   * @returns 
   */
  async function loginIn({ address, nonce, signature,balance }) {
    if (!signature) return;
    const params = {
      "address": address,
      "nonce": nonce,
      "signature": signature,
      "token": "",
      "hold_balance":balance,
    }
    let res = await login(params)
    if (res?.code === 200) {
      const { token, address,hold_amount='-' } = res?.data || {}
      dispatch(setAddress(address.toLowerCase()))
      dispatch(setUserAmount(hold_amount))
      dispatch(setAccountPopShow(true));
      let expires = new Date(new Date() * 1 + 24 * 60 * 60 * 1000)
      Cookies.set('token', token, { expires })
      Cookies.set('amount', hold_amount, { expires })
    }

  }
  function logoutWallet() {
    dispatch(setAddress(''))
    Cookies.remove('token')
    Cookies.remove('balance')
    Cookies.remove('amount')
    setActiveKey('Home')
    console.log(location.pathname)
    if (location.pathname !== '/home' && !isInviteLink()) {
      jumpTo('/home')
    }
  }
  const isInviteLink = () => {
    const isInvite = location.search && location.pathname === "/airdrop"
    if (isInvite) {
      setActiveKey('Airdrop')
    }
    return isInvite
  }
  /**
   * 导航栏点击
   * @param {*} i navItem
   */
  function navItemClick(i) {
    if (i.src) {
      window.open(i.src, '_self')
      return
    }
    if (!Cookies.get('token') && !['Home', 'R2E-$16DAO', 'Token', 'Airdrop'].includes(i.label)) {
      dispatch(setWarnContent('Please connect to your MetaMask Wallet!'))
      logoutWallet()
      return;
    }
    setActiveKey(i.label)
    i.path && jumpTo(i.path)
  }
  function writerEntrance() {
    if (!Cookies.get('token')) {
      dispatch(setWarnContent('Please connect to your MetaMask Wallet!'))
      logoutWallet();
      return;
    }
    jumpTo('/mystory')
  }
  return (
    <>
      <Navbar variant="dark" expand="md" className={{ 'nav-bar': true, 'top-bar': isTopBar }}>
        <Navbar.Brand href="/#/home">
          <Image src={logo} width={120}></Image>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={activeKey}>
            {navDataList.map(i => <Nav.Link onClick={e => navItemClick(i)} key={i.label} eventKey={i.label}>{i.label}</Nav.Link>)}
            {/* <Button variant="outline-secondary" className='text-line-1 writer' onClick={e => writerEntrance()}>Writer Entrance</Button> */}
            <Nav.Link  className='text-line-1 ' onClick={e => writerEntrance()}>Writer Entrance</Nav.Link>
            {address ?
              <div className='address-btn-new' ref={ref} >
                {/* <Button variant="primary" className='text-line-1 address-btn' onClick={e => addressClick(e)}><span>{`${address.slice(0, 4)}***${address.slice(-4)}`}</span></Button> */}
                <Nav.Link className='text-line-1' onClick={e => addressClick(e)}><span>{`${address.slice(0, 4)}***${address.slice(-4)}`}</span></Nav.Link>
                <Overlay show={accountPopShow}
                  container={ref}
                  target={target}
                  placement="bottom">
                  <Popover id="popover-contained" className='account-pop' onClick={e => e.stopPropagation()}>
                    <Popover.Body>
                      <div>
                        <div className='flex-row-space-between flex-row-align-start m-g-b-16'>
                          <div className='hold'>
                            <div className='hold-key'>Your balance</div>
                            <div className='flex-row-space-between hold-val'>
                              <Image src={coinGif} width={32} fluid></Image>
                              <div className="hold-val">{userBalance}</div>
                            </div>
                          </div>
                          <div className='hold'>
                            <div className='hold-key'>Rewards you have earned</div>
                            <div className="hold-val">{userAmount}</div>
                          </div>
                          <div>
                            <Image src={logoutgray} onClick={e => logoutWallet()} width={24} height={24} className="pointer"></Image>
                          </div>
                        </div>
                        <div className='flex-row-space-between'>
                          <div className=' earning-title'>Earning $16DAO</div>
                          <Image src={gift} width={50}></Image>
                        </div>
                        <div className='earning-sub'>Hold tokens in your wallet and earn by reading</div>
                        <div className='reward-rules'>Rules:
                            <p>1. At the end of each novel, when you tap the "Finish” button, system will check whether your wallet is holding tokens or not; and according to the number of tokens held, rewards will be issued according to the level. If you don't have tokens in your wallet, please <i className='pointer'  onClick={e=>window.open('https://www.xt.com/en/trade/16dao_usdt','_blank')}>buy $16DAO</i> first;</p>
                            <p>2. Each novel will only be rewarded once;</p>
                            <p>3. The reward tokens for reading will be distributed to the wallet on the last day of each month.</p>
                            <p>4. The table below shows the number of tokens you hold and the number of rewards you can get:</p>
                        </div>
                        <div className="post-content flex-row flex-row-space-between m-g-b-16">
                          <Image src={rewardTable} width={'100%'} ></Image>
                        </div>
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
              : <>
                {/* <Button variant="primary" disabled={loading} onClick={e => getMetamskConnect()} className='text-line-1'>Connect To Earn</Button> */}
                <Nav.Link disabled={loading} onClick={e => getMetamskConnect()} className='text-line-1'>Connect To Earn ></Nav.Link>

              </>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Alert key='warning' variant='warning' show={showWarn} style={{ zIndex: 999999, position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
        {warnContent}
      </Alert>
      <ToastContainer position='middle-center' className='wallet-pop'>
        <Toast bg="light" show={walletPopShow} className="wallet-toast">
          <Toast.Body>
            <div className='flex-row-space-between wallet-container'>
              <CloseButton className='close-btn' onClick={e => setWalletPopShow(false)} />
              <div className='got-it'>
                <Image src={metamask} width={60} />
                <div className='info-sec-title'>Connect Metamak Wallet first</div>
                <Button className='got-btn' onClick={e => window.open("https://metamask.io/")}>Got it</Button>
                <div className='info-thd-title pointer' onClick={e => window.open("https://metamask.io/")}>I have no wallet</div>
                <div className='info-p'>By connecting your wallet you agree to the Terms Of Service and Privacy Policy</div>
              </div>
              <div className='wallet-info'>
                <div className='info-title'>What is a Wallet?</div>
                <div className='info-sec-title'>Safety Way To Log In</div>
                <div className='info-p'>Users have no need to create new accounts and passwords on every plantform. Just need a decentralized wallet what is so safe to log in.</div>
                <div className='info-sec-title'>Pocket Of Your Asset</div>
                <div className='info-p'>Users could seed, receive, store and display digital assets. Everything will happen on blockchain and everything is safe.</div>
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}
export default NavList