import './index.scss'

import { useState, useEffect, useContext } from 'react'
import twitter from '@/static/images/twitter.png'
import { useSelector, useDispatch } from 'react-redux';
import { Image, Button, ProgressBar, Alert } from 'react-bootstrap'
import { TwitterShareButton } from 'react-share';
import { eventEmitter } from '@/store/eventEmitter';
import Rules2 from '../rules2'
function Phase({
  user_airdrop_amount2,
  user_airdrop_amount1,
  isViolation,
  airdropState,
}) {
  const { address } = useSelector(state => state.home)
  const text = "I just claimed $16DAO token airdrop, so easy and safety. You'd better claim it NOW!\r\n"
  const hashtags = ["16DAOCoin", "web3", "DeFi", "Airdrop"]
  const shareUrl = `${window.location.href.split('?').at(0)}?inviter=${address}`
  const loginToCheck = () => {
    if (!address) {
      eventEmitter.emit('connectEvent', true)
      // dispatch(setWarnContent('Please authorize to access your account!'))
      return;
    }
    console.log('address:', address)
  }
  const numFormat = number => {
    number = number > 0 ? number : 0
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return (
    <div className='phase'>
      {airdropState === "finished"?
      <div className="phase-item">
        <div className="title">Phase 2 Airdrop Recap</div>
        <div className="main flex-row-space-between">
          <div className="content">
            <div className="result flex-row-space-around">
              <div className="give-away">
                <div className='num'>{numFormat(700000)}</div>
                <div className='tag'>Token Gived Away</div>
              </div>
              <div className="holders">
                <div className='num'>{numFormat(10000)}</div>
                <div className='tag'>People Claimed Successful(Holders)</div>
              </div>
            </div>
            <div className="desc">
            The total number of users who participated in phase 2 of airdrop activity to claim was 10,000, a total of 700,000 tokens were offered, accounts that meet the requirements will receive 70 tokens.
            </div>
            <div className="desc">
            Which time to airdrop? We are currently planning token LISTIN, expect to deploy smart contracts in November, as well as airdrop tokens to wallets that meet the claiming rules according to the number of claims. Please refer to the official information to avoid being deceived.
            </div>
          </div>
          <div className="rewards">
            <div className="box">
              <div className='num'>{numFormat(user_airdrop_amount2)}</div>
              <div className='desc'>My current rewards in Phase 2</div>
              <div>
                {!address ? <Button onClick={loginToCheck} className='lg-btn'>Login to check</Button> :
                  user_airdrop_amount2 > 0 ? <TwitterShareButton
                    url={shareUrl}
                    title={text}
                    hashtags={hashtags}
                    className='lg-btn btn btn-primary' style={{ backgroundColor: '123', color: '#fff' }}>
                    <Image src={twitter} className="twitter-icon"></Image>Show Off
                  </TwitterShareButton> : <div>The system has detected that you did not participate in the first phase of the airdrop claim.</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>:<></>}

      <div className="phase-item">
        <div className="title">Phase 1 Airdrop Recap</div>
        <div className="main flex-row-space-between">
          <div className="content">
            <div className="result flex-row-space-around">
              <div className="give-away">
                <div className='num'>{numFormat(2300000)}</div>
                <div className='tag'>Token Gived Away</div>
              </div>
              <div className="holders">
                <div className='num'>{numFormat(20068)}</div>
                <div className='tag'>People Claimed Successful(Holders)</div>
              </div>
            </div>
            <div className="desc">
              We strongly believe that human value can be stored and transferred, done in the form of tokens. The total number of users who participated in the first phase of airdrop activity to claim was 53,774, a total of 3,000,000 tokens were offered, but in fact some users were cheating, by submitting multiple accounts to register for the claim by a single person, we would like to emphasise once again that this is destroying the spirit of Web3, and this is a behaviour that $16DAO does not advocate, and we will not be airdropping tokens to wallets flagged as brushed accounts.
            </div>
            <div className="desc">
              After careful review by our technical staff and operations team, <span>20,068</span> wallet addresses were able to receive airdrops, and we have redistributed the swiped claim shares to accounts that meet the requirements, which were originally planned to receive 50 tokens per account, and now, accounts that meet the requirements will receive 80 tokens.
            </div>
            <Rules2></Rules2>
          </div>
          <div className="rewards">
            <div className="box">
              <div className='num'>{numFormat(user_airdrop_amount1)}</div>
              <div className='desc'>My current rewards in Phase 1</div>
              <div>
                {!address ? <Button onClick={loginToCheck} className='lg-btn'>Login to check</Button> :
                  user_airdrop_amount1 > 0 ? <TwitterShareButton
                    url={shareUrl}
                    title={text}
                    hashtags={hashtags}
                    className='lg-btn btn btn-primary' style={{ backgroundColor: '123', color: '#fff' }}>
                    <Image src={twitter} className="twitter-icon"></Image>Show Off
                  </TwitterShareButton> : <div>The system has detected that you did not participate in the first phase of the airdrop claim.</div>
                }
              </div>
              {isViolation ? <div className='sorry'>SORRY.<br />
                Owner of this wallet has used multiple wallets to claim the airdrop multiple times, which is not in line with the spirit of Web3 decentralisation, and does not meet the $16DAO airdrop requirement, so please participate next time.</div> : <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Phase;