import './index.scss'

function Rules2(){
  return (
    <div className='rules'>
      <div className="title heavy-title f-15">Rules of the airdrop</div>
      <div className="content">
        <div className="item">1. The quantity collected is equal to the actual number of airdrops;</div>
        <div className="item">2. Please follow <span className='heavy-title pointer' onClick={e=>window.open('https://twitter.com/16DAOCoin','_blank')} >@16DAOCoin</span>  on twitter to receive notifications;</div>
        <div className="item">3. There are a total of 5 levels of invitation activities, and you can enhance your level by inviting your twitter friends to participate in receiving airdrops, each level has a different number of invited friends, and the rewards you get are as shown on the website, as:
          <div className="level">- Civilian requires 0 invitations;</div>
          <div className="level">- Centurion requires 1 invite and receives a total of 70 tokens;</div>
          <div className="level">- Civilian Protection Officer requires 10 invitations and receives a total of 200 tokens;</div>
          <div className="level">- Municipal Officer requires 50 invitations and receives 500 tokens;</div>
          <div className="level">- Provincial Consul requires 120 invitations for a total of 1,000 tokens;</div>
        </div>
        <div className="item">4. We always insist on fairness and transparency, but if there are too many people participating at the same time during the event, the statistics will be delayed, please refer to the final statistics;</div>
        <div className="item">5. Crypto has high risk, please operate with caution.</div>
      </div>
    </div>
  )
}

export default Rules2