import { useState, useEffect } from "react";
import { getBookDetailList } from "@/data";
import { groupBy } from '@/utils'
import { mockBookChapter ,isMock} from "@/mock";
import { useDispatch } from "react-redux";
import Cookies from 'js-cookie';
import { setAddress,setUserAmount, setWarnContent } from "@/store";
export default function useBookChapter({ bookId }) {
    const [bookChapter, setBookChapter] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        loadChapterList()
    }, [bookId])
    const loadChapterList = async () => {
        if (!isMock&&!Cookies.get('token')) {
          dispatch(setWarnContent('Please connect to your MetaMask Wallet!'))
          dispatch(setAddress(''))
          return;
        }
        if (!bookId) return;
        const activeBookChapter = {
            code:200,
            data:{}
        }
        activeBookChapter.data = mockBookChapter.data.filter(i=>+i.book_id === +bookId)
        const res = isMock?activeBookChapter:await getBookDetailList({
            "book_id": +bookId,
            "limit": 99999999,
            "offset": 0
        })
        console.log('res:',res)
        if (res?.code === 200) {
            const groupBooksRes = groupBy(res.data, 'volume', 'volume_title')
            console.log('groupBooksRes:',groupBooksRes)
            setBookChapter(groupBooksRes)
        }
    }
    return {bookChapter,setBookChapter}
}