import './index.scss'
import { Toast ,Image} from 'react-bootstrap'
import useBookList from '@/hooks/useBookList'
import coverErr from '@/static/images/coverErr.png'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
function AllNovelDialog({ show, onClose }) {
    const { bookList } = useBookList({ limit: 20 })
    const jumpTo = useNavigate()
    useEffect(() => {
        console.log('dialog:', bookList)
    })
    return (
        <Toast className='all-novel-dialog' show={show} onClose={e => onClose(false)}>
            <Toast.Header className='flex justify-end'>
            </Toast.Header>
            <Toast.Body>
            {bookList.map(book => <Image className='novel-item' width={70} onClick={e => jumpTo(`/book?id=${book.id}`)} key={book.id} src={book.image_url || coverErr} onError={e => e.target.src = coverErr}></Image>)}
                
            </Toast.Body>
        </Toast>
    )
}
export default AllNovelDialog