
import { useDispatch } from 'react-redux';
import { setLoadingCoverStatus } from '@/store';
import { getBookList } from '@/data';
import { useState,useEffect } from 'react';
import {isMock,mockBookList} from '@/mock'
export default function useBookList({pageNo=1,pageSize=9999}){
    const dispatch = useDispatch()
    const [bookList,setBookList] = useState([])
    useEffect(()=>{
        loadBookList()
    },[])
    useEffect(()=>{
        console.log(`bookList ${pageSize}`,bookList)
    },[bookList])
    const loadBookList = async () =>{
        dispatch(setLoadingCoverStatus(true))
        const mockBookListRes = {
            code:200,
            data:{}
        }
        mockBookListRes.data.list = mockBookList.data.list.slice(0,pageSize)
        console.log('pageSize:',mockBookList)
        let res = isMock?mockBookListRes:await getBookList({
          "page_size": pageSize,
          "page_no": pageNo,
          "title": "",
          "status":4
        });
        // const res = {
        //     code:200,
        //     data:{
        //         list:mockBookList
        //     }
        // }
        dispatch(setLoadingCoverStatus(false))
        if (res?.code === 200) {
        const { data } = res;
        const { list } = data
        setBookList(list || [])
        }
    }
    return {bookList,setBookList}
}