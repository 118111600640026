import './index.scss'
import coin from '@/static/images/coin.gif'
import { Image, Button } from 'react-bootstrap'
import whitePage from '@/static/images/whitePage.jpg'
import app from '@/static/images/app.png'
import HomeLayout from '@/layout/homeLayout'
function Home() {
    return (
        <HomeLayout>
            <div className='main p-d-l-20 p-d-r-20'>
                <h2 className="title">Web3 IP Operations and Management Group</h2>
                <h3 className="sub-title">I. Introduction to DZ Labs</h3>
                <h4 className="thr-title">1.1 Business Overview</h4>
                <p className="content">DZ Labs is an IP operation and management group that operates on a Web3 organizational structure. Its business covers IP reserves, encrypted payments, content IP-ification, and investment, with novels as the starting point of its IP reserve. It uses the most efficient Web3 to manage the process from IP production to final monetization, combining AIGC’s powerful capabilities to improve the efficiency of IP content, and achieve the goal of the fastest and largest commercialization.</p>
                <div className='flex flex-row'>
                    <div className='card-l flex flex-row' onClick={e => window.open('https://polygonscan.com/token/0x4A5599a249fDC53bD4746e7d9078eDb7543bD0A9#balances')}>
                        <div className='left'>
                            <div className='thr-title'>Motivated by Token $16DAO</div>
                            <div className='content'>The purpose of the DZ Labs treasury is to motivate and ensure that the DAO develops and grows, and that DAO members have more commercial interests. The management members of the treasury are recommended by the DAO organization and re-elected every fiscal year.</div>
                            <div className='pointer'>Find out the token >></div>
                        </div>
                        <div>
                            <Image src={coin} width={140} ></Image>
                        </div>
                    </div>
                    <div className='card-r flex flex-row'>
                        <div className='left'>
                            <div className='thr-title'>Read on Mobile</div>
                            <div>
                                <Button className='white-btn m-g-b-13' onClick={e => window.open('https://play.google.com/store/apps/details?id=xyz.dao16.flutter_16dao&pcampaignid=web_share', '_blank')}>Googole Play</Button>
                                <Button className='white-btn m-g-b-13' onClick={e => window.open('/16dao.apk', '_blank')}>Android APK</Button>
                                <Button className='white-btn m-g-b-13' variant="outline-secondary">APP Store</Button>
                            </div>
                        </div>
                        <div>
                            <Image src={app} width={130} ></Image>
                        </div>
                    </div>
                </div>

                <h4 className="thr-title">1.2 Business Modules</h4>
                <h5 className="for-title">1.2.1 IP Reserves</h5>
                <p className="content">DZ Labs has built a platform 16dao.xyz to earn $16DAO tokens by reading novels, which is used to reserve novel IPs and precipitate crypto users, to build an operation position for DZ Labs, and the platform will be able to independently complete its own business closed loop.</p>

                <h5 className="for-title">1.2.2 Crypto Payments</h5>
                <p className="content">This is the payment platform in DZ Labs’ ecosystem, linking the users precipitated by 16dao.xyz, NFT IP-authorized consumers, and IP-authorized merchants. Users can buy IP co-branded commodities and global services with $16DAO tokens, which will provide a channel for Web2 businesses to enter Web3, building an encrypted platform likes Meituan company.</p>

                <h5 className="for-title">1.2.3 Content IP-ization</h5>
                <p className="content">DZ Labs will carry out content IP based on the reserve of novel content, and there are four certain directions, the first one is to NFT the novel content to create IP, by cooperating with the white brand merchants in China’s huge supply chain to make goods, or authorize the merchants to make goods to earn authorization fees, and at the same time, these NFTs will become the SBT in the ecosystem of DZ Labs; the second one is to make movies, and the mature AIGC technology will reduce the cost of making movies, thus reducing the cost of making movies and the cost of making movies. technology is reducing the cost of making movies, which gives DZ Labs’ novel content better IP branding opportunities, DZ Labs will use Web3 to recruit producers to build IP together; the third direction is GameFi, encryption allows the right to use the assets to return to the user, which completely subverts the way centralized games treat their users, and a good GameFi not only gets a large number of users, but even lasts for many years. Good GameFi can not only acquire a large number of users, but also bring brand benefits to IP for many years; the fourth is to build its own platform for making goods, and provide influential NFTs around the world with the ability to pledge copyrights for IP authorization; DZ Labs will use its IP authorization advantage to broaden the commercial cash outlet for these NFT projects, and at the same time, develop more content with IP attributes.</p>

                <h5 className="for-title">1.2.4 Foundation</h5>
                <p className="content">DZ Labs will allocate a portion of its group income on a fiscal year basis to be managed by DAO organizations for investing or acquiring businesses that are beneficial for DZ Labs’ long-term growth. Priority will be given to encrypted technology, AIGC technology, and Web3 companies.</p>


                <h3 className="sub-title">2. Introduction to the Novel Industry</h3>
                <h4 className="thr-title">2.1 Market Scale</h4>
                <p className="content">Looking at the Chinese online literature market alone, the China Audio-Video and Digital Publishing Association’s “2018 China Network Literature Development Report” shows that there were 24.42 million online literature works in China in 2018. According to the latest statistics, the compound growth rate of the number of novels has reached 34% from 2016 to 2021.
                    There are numerous online literature authors in China, with over 17.55 million authors and a rich reserve of works. In terms of adapting IPs into film and television works alone, it has driven the growth of a trillion-yuan market. In February 2021, data from the “2019–2020 Annual Network Literature IP Film and Television Adaptation Potential Assessment Report” showed that in the first two years, online literature IPs drove the total output value of the cultural industry by over 1 trillion yuan.</p>

                <h4 className="thr-title">2.2 Pain Points</h4>
                <h5 className="for-title">2.2.1 Unequal Income Distribution</h5>
                <p className="content">Data from the 12th Author Income List from the China Audio-Video and Digital Publishing Association indicates that the income of online literature authors shows a pyramid structure, with a small number of authors at the top receiving the most income. Approximately 610,000 authors in the middle receive a guaranteed income of only a few hundred yuan, while the remaining 16.94 million authors have no income. This means that 96.5% of authors have no income in the Chinese online literature ecosystem.</p>

                <h5 className="for-title">2.2.2 Few Opportunities for Adaptation into Film and Television Works</h5>
                <p className="content">According to data from the research firm EntGroup, only 30 online literature works were adapted into TV dramas in 2018, and only 35 in 2019. Although the user base of video streaming websites is increasing, the opportunities for online literature to be adapted into film and television are scarce.</p>

                <h5 className="for-title">2.2.3 Loss of Author Copyright</h5>
                <p className="content">Almost all Chinese online literature platforms are in a state of monopoly. If online literature authors want to publish their works on these platforms, the platforms almost always require exclusive authorization from the authors. The authorization period is as short as five years and as long as fifty years after the author’s death, during which time the platform has extensive rights. They can modify works arbitrarily according to commercial demands, distribute, transfer, and sell copyrights without the author’s consent.</p>

                <h4 className="thr-title">2.3 Opportunities</h4>

                <h5 className="for-title">2.3.1 Huge User Base</h5>
                <p className="content">As of December 2021, the total number of online literature users in China had reached 502 million, accounting for nearly 50% of China’s internet users, according to the 49th “China Internet Development Report.” According to data from China’s largest online literature platform, Yuewen Group, among the new users of its platform in 2021, more than 60% are from the post-1995 generation. This means that Generation Z users continue to pour in, who are the main force of new consumption patterns and supporters of the encrypted economy.</p>
                <p className="content">Looking at the foreign market, in October 2021, the China Writers Association released the “China Online Literature International Communication and Development Report” in Wuzhen, Zhejiang Province. Chinese online literature has spread more than 10,000 works overseas, with over 100 million users reading them on overseas websites and apps, covering most countries and regions in the world.</p>

                <h5 className="for-title">2.3.2 Rich and Low-Cost Novel IPs</h5>
                <p className="content">In China alone, the number of completed novel reserves has exceeded 24 million. While the number of novels continues to increase, there are no organizations that can provide commercial operation services for them. DZ Labs will use Web3 to sign high-quality novels into the 16dao.xyz platform, allowing 500 million Chinese readers and 100 million overseas readers to select their favorite online literature works. This will bring fame to the authors, and DZ Labs will be able to reserve a large number of IPs at lower or even free costs.</p>

                <h5 className="for-title">2.3.3 Market Scale</h5>
                <p className="content">According to authoritative organization data, in 2021, the sales revenue generated by global IP licensed goods and services increased to 315.5 billion U.S. dollars, while the global film market is about 328.2 billion U.S. dollars, not including paid television subscription markets. DZ Labs will operate its novel IPs and films in this huge market scale, providing higher commercial returns for the group.</p>

                <h4 className="thr-title">3. Organizational Structure</h4>
                <h5 className="for-title">3.1 DAO</h5>
                <p className="content">The mission of the DAO is to drive the growth of DZ Labs, attract more excellent novels to join the ecosystem, and enable these novels to continue generating cash and cultural value. DAO members are composed of individuals who hold $16DAO tokens, including novel writers, crypto developers, operations personnel, and DZ Labs investors. DAO members enjoy all the profit-sharing rights of DZ Labs, reflected in:</p>
                <p className="content">● Governance token premium</p>
                <p className="content">All members who join the DAO hold $16DAO tokens, which were initially distributed for free by the DAO. After the token is listed, members can also purchase it at a low price. As the DZ Labs ecosystem develops and the value storage capacity increases, the premium of $16DAO tokens will also increase. Early holders of the token can enjoy higher premiums.
                </p>
                <p className="content">● Cash income</p>
                <p className="content">When the DAO promotes the filming of novels as movies or joint IP projects, these commercial activities involve a large amount of revenue such as box office, authorization transfer fees, and advertising fees. The DAO will manage these revenues and distribute them to all members in cash according to the fiscal year.</p>
                <p className="content">● NFT income</p>
                <p className="content">Any element in the novel can issue NFT, and DAO members can enjoy the profit-sharing of NFT sales and even get NFT airdropped for free. When NFT is listed on Opensea, DAO members can sell these NFTs to earn income.

</p>
                <p className="content">● GameFi</p>
                <p className="content">Science fiction novels operate more smoothly on GameFi, which can give the game mother culture and a complete worldview. When promoting these GameFi novels, DAO members can enjoy the early token airdrop and NFT airdrop, and even participate in the whitelist of token purchases at the earliest stage. When the game is listed, the premium of its tokens and NFTs will bring more income to DAO members.</p>
                
                <h5 className="for-title">3.2 Treasury</h5>
                <p className="content">The purpose of the DZ Labs treasury is to motivate and ensure that the DAO develops and grows, and that DAO members have more commercial interests. The management members of the treasury are recommended by the DAO organization and re-elected every fiscal year.</p>
                <p className="content">● Revenue sources</p>
                <p className="content">The treasury’s revenue sources mainly come from two aspects. The first is 13% of the original quantity of $16DAO tokens held. In the case of not affecting the token price, the DAO can sell $16DAO tokens for cash. The second is the income from IP monetization.</p>
                <p className="content">● Responsibilities</p>
                <p className="content">The treasury is responsible for ensuring the healthy operation of funds, using the treasury to promote the development and growth of the DAO, and investing in other projects with a reasonable proportion of funds, or even supporting the development of projects within the DAO.</p>
                <p className="content">● Obligations</p>
                <p className="content">The treasury is obliged to disclose all financial data details on a monthly, quarterly, and fiscal year basis. When funds enter or exit, the treasury is obliged to disclose the details to DAO members.</p>
                
                <h5 className="for-title">3.3 Token Information</h5>
                <p className="content">
                    <Image src={whitePage} width={700}/>
                </p>
                
                <h3 className="sub-title">IV. Roadmap and Milestones</h3>
                <h4 className="thr-title">4.1 Technical Progress</h4>
                <p className="content">The 16dao.xyz reading platform has been launched, including a web version and an app.</p>
                
                <h4 className="thr-title">4.2 Business Progress</h4>
                <p className="content">● Novels</p>
                <p className="content">Currently, three novels have been signed, including the urban romantic novel “Peanut Note” in Chinese and its English version “Divorce Day,” as well as the science fiction series “Star Chart Designer 1: Federal War” part one. The second part of the series is expected to be signed as well.</p>
               
                <p className="content">● Publishing</p>
                <p className="content">DZ Labs has partnered with Yusi Publishing, it will provide DZ Labs with novel copyright procurement management and undertake DZ Labs’ novel publishing business.</p>
               
                <p className="content">● NFT</p>
                <p className="content">The first batch of NFTs developed by DZ DAO based on the novel “Peanut Note” has been listed on Opensea. Artists in DZ DAO have created a total of 60 NFTs. This series of NFTs is a high-priced art collection of Catwomen, the female protagonist in the novel “Peanut Note.”</p>
                
                <h3 className="sub-title">V. Contact Information</h3>
                <h4 className="thr-title">5.1 Official Links</h4> 
                <p className="content">Website: <a className='link' href='https://16dao.xyz/'>https://16dao.xyz/</a></p>
                <p className="content">Opensea link: <a className='link' href='https://opensea.io/collection/catwomanstar'>https://opensea.io/collection/catwomanstar</a></p>
                <p className="content">Twitter: <a className='link' href='https://twitter.com/16DAOCoin/'>https://twitter.com/16DAOCoin/</a></p>
                
                <h4 className="thr-title">5.2 Communication</h4> 
                <p className="content">For investors, business cooperation, novel applications, please email to us:</p>
               
                <h4 className="thr-title"><a className='link' href="mailto:dzlabs2022@gmail.com">dzlabs2022@gmail.com</a></h4> 
               
                </div>
        </HomeLayout>
    )
}
export default Home;