import axios from 'axios'
import Loading from '@/components/loading';
import {createAlert} from '@/components/dzAlert';
import Cookies from 'js-cookie';
import history from '@/history';
import ReactDOM from 'react-dom/client';
const baseUrl = process.env.REACT_APP_BASEURL;
const body = document.querySelector('body');
axios.defaults.timeout = 60000
let httpCount = 0
export function createLoading() {
  if (!document.querySelector('#loading-container')) {
    const loadingContainer = document.createElement('div')
    loadingContainer.id = 'loading-container'
    body.append(loadingContainer)
    const loadingRoot = ReactDOM.createRoot(document.querySelector('#loading-container'));
    loadingRoot.render(
      <><Loading /></>
    );
  }
  httpCount++;
  document.querySelector('#loading-container').style.display = 'block'
}
export function removeLoading() {
  httpCount--;
  if (httpCount === 0) {
    document.querySelector('#loading-container').style.display = 'none'
  }
}
function failToIndex() {
  if (!Cookies.get('token')) return;
  setTimeout(() => {
      Cookies.remove('token')
      history.push('/')
      window.location.reload()
  }, 3000)
}
class HttpRequest {
  constructor(baseUrl) {
    console.log(baseUrl)
  }
  getInsideConfig() {
    const config = {
      baseURL: baseUrl,
      header: {}
    }
    return config

  }
  interceptors(instance, url) {
    /**请求拦截 */
    instance.interceptors.request.use((config) => {
      console.log('拦截和处理请求');
      console.log('config: ', config);
      createLoading()
      const token = Cookies.get('token');
      if (token) {
        config.headers.authorization = token;
      }
      return config;
    });
    /**响应拦截 */
    instance.interceptors.response.use(
      (res) => {
        removeLoading()
        console.log('请求成功处理响应', res);
        if (res && res.status === 200) {
          if (res?.data?.code !== 200) {
            createAlert({ type: 'danger', content: res?.data?.msg })
            if(res.data.code === 400){
              // failToIndex();
            }
          }
          return res.data;
        }
      },
      (error) => {
        removeLoading()
        createAlert({ type: 'danger', content: error?.response?.data?.msg||error.message })
        console.log('请求失败处理响应', error);
        failToIndex();
        return error?.response?.data;
      },
    );
  }

  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}

const axiosObj = new HttpRequest(baseUrl);

export default axiosObj;