import './index.scss';
import FormInput from '../formInput';
function FormItem(props) {
    console.log(props)
    return (
        <div className='dz-form-item flex-row-align-start' ref={props?.itemRef}>
            <div className={props.required?'required dz-form-label':'dz-form-label' }>{props.label}</div>
            <div className='dz-form-content'>{props.content||props.children||<FormInput />}</div>
        </div>
    )
}

export default FormItem;