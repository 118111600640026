import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from 'react-router-dom'
import history from './history';
import {initAlert} from '@/components/dzAlert'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router history={history}>
      <App />
    </Router>
);
initAlert();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
