import './index.scss'
import { useEffect, useRef } from 'react';
function FormTextArea(props) {
  const ref = useRef();
  useEffect(()=>{
    ref.current.value = props.defaultValue||''
  },[])
  useEffect(()=>{
    ref.current.value = props.defaultValue||''
  },[props])
    return (
        <div className='dz-form-textarea'>
            <textarea ref={ref} onInput={e=>props.onInput(e.target.value)} defaultValue={props.defaultValue} placeholder={props.placeholder} readOnly={props.readOnly} disabled={props.disabled} maxLength={props.maxLength} minLength={props.minLength}>
            </textarea>
        </div>
    )
}
export default FormTextArea;