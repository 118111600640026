import "./index.scss";
import { Image, Button } from "react-bootstrap";
import novel1 from "@/static/images/novel1.png";
import coin from "@/static/images/coin.gif";
import NovelChapterPop from "@/components/novelChapterPop";
import { useSearchParams, useNavigate } from "react-router-dom";
import { createAlert } from '@/components/dzAlert'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import coverErr from "@/static/images/coverErr.png";
import { useDispatch } from "react-redux";
import { setAddress,setUserAmount, setWarnContent } from "@/store";
import { groupBy, useDebounce } from "@/utils";
import DzPop from '@/components/dzPop'
import { getBookDetailList,getBookById, rewardCreate } from '@/data'
import HomeLayout from "@/layout/homeLayout";
import BookChapter from "./components/bookChapter";
import useBookInfo from "@/hooks/useBookInfo";
import app from "@/static/images/app.png";
import useBookChapter from "@/hooks/useBookChapter";
let scrollFn = null;
function Book() {
  const readEl = document.getElementById("read-container");
  const [gotCoin, setGotCoin] = useState()
  const { userBalance } = useSelector(state => state.home)
  const [init, setInit] = useState(true);
  const [search] = useSearchParams();
  const { userAmount } = useSelector(state => state.home)
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.home);
  const [bookId, setBookId] = useState();
  const bookInfo = useBookInfo({ bookId });
  const [activeChapter, setActiveChapter] = useState({})
  const [nextChapter, setNextChapter] = useState({})
  const { bookChapter, setBookChapter } = useBookChapter({ bookId });
  const [finishPopShow, setFinishPopShow] = useState()
  let jumpTo = useNavigate();
  useEffect(() => {
    setBookId(search.get("id"));
  });
  useEffect(() => {
    if (init && bookChapter.length) {
      updateReadChapter()
      setInit(false);
      setActiveChapter(bookChapter.at(0)?.list?.at(0))
      readChapter(bookChapter.at(0)?.list?.at(0));
    }
  }, [bookChapter, bookId]);
  useEffect(() => {
    setInit(true)
    readEl?.scroll({ top: 0, behavior: 'smooth' })
  }, [bookId])
  useEffect(() => {
    console.log('bookChapter effect:', bookChapter)
  }, [bookChapter])
  const readChapter = (chapter, scroll) => {
    if (scroll) {
      readEl.removeEventListener("scroll", scrollFn);
    }
    if (scroll && chapter) {
      scrollToElement(
        `${chapter.book_id}-${chapter?.volume}-${chapter.chapter}`
      );
    }
    if (chapter) {
      readCurChapter(chapter, scroll);
      setActiveChapter(chapter)
    }
  };
  const scrollToElement = (id,offset=200) => {
    var element = document.getElementById(id);
    const container = document.getElementById('read-container')
    if (element) {
      element.scrollIntoView({
        behavior: "smooth", // 平滑滚动
        // block: "center",
        // inline: "start",
      });
      // 微调滚动位置
      setTimeout(()=>{
        const currentScrollTop = container.scrollTop;
        container.scrollTo({
          top: currentScrollTop - 120,
          behavior: 'smooth'
        });
      },1000)
    }
  };
  function startRead(event, chapter, paused) {
    const scrollTop = readEl.scrollTop;
    // console.log('scrollTop:',scrollTop)
    if (chapter && bookChapter) {
      const el = document.getElementById(
        `${chapter.book_id}-${chapter?.volume}-${chapter.chapter}`
      );
      const elHeightStart = el.offsetTop - 110;
      const scrollHeight = elHeightStart + el.offsetHeight;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        // if(!paused){
        console.log("finishChapter", chapter);
        finishedChapter(chapter, paused);
        // }
        updateReadChapter(chapter)
        const nextChapter = findNextChapter(bookChapter, chapter);
        setActiveChapter(nextChapter)
        nextChapter && readCurChapter(nextChapter);
      }
    }
  }
  const updateReadChapter = (chapter) => {

    const readBook = JSON.parse(localStorage.getItem(`read_${address}_${bookId}`) || '{}')
    console.log('readBook:', readBook)
    if (chapter) {
      readBook[`book_${chapter.volume}_${chapter.chapter}`] = true;
      localStorage.setItem(`read_${address}_${bookId}`, JSON.stringify(readBook))
      bookChapter.forEach(vol => {
        vol?.list?.forEach(chap => {
          if (chap.volume === chapter.volume && chap.chapter === chapter.chapter) {
            chap.read = true
          }
        })
      })
    }
    else {
      bookChapter.forEach(vol => {
        vol?.list?.forEach(chap => {
          chap.read = readBook[`book_${chap.volume}_${chap.chapter}`]
        })
      })
    }
    console.log('bookChapter:', bookChapter)
    setBookChapter([...bookChapter])
  }
  const finishedChapter = (chapter, paused) => {
    console.log("finish:", chapter);
    readEl.removeEventListener("scroll", scrollFn);
    const list = bookChapter.map((i) => {
      i.list.forEach((chap) => {
        if (
          chap.volume === chapter.volume &&
          chap.chapter === chapter.chapter
        ) {
          chap.read = true;
        }
      });
      return i;
    });
    setBookChapter(list);
  };
  const findNextChapter = (list, item) => {
    if (!list.length || !item) return
    let nextChapter;
    list.forEach((i, idx) => {
      if (i.volume === item.volume) {
        i.list.forEach((chap, idxx) => {
          if (chap.chapter === item.chapter) {
            if (!nextChapter && i.list[idxx + 1]) {
              nextChapter = i.list[idxx + 1];
            }
            if (!nextChapter && list[idx + 1] && list[idx + 1].list?.at(0)) {
              nextChapter = list[idx + 1].list?.at(0);
            }
          }
        });
      }
    });
    return nextChapter;
  };
  function readCurChapter(chapter, paused) {
    scrollFn = function (event) {
      startRead(event, chapter, paused);
    };
    readEl.addEventListener("scroll", scrollFn);
  }
  const readNextChapter = () => {
    const nextChapter = findNextChapter(bookChapter, activeChapter)
    if (!nextChapter) {
      dispatch(setWarnContent('No more chapter!'))
      return
    }
    readChapter(nextChapter, true)
  }

  function finishRead() {
    setFinishPopShow(true)
  }
  function closePop() {
    setFinishPopShow(false)
  }
  const getAmountByBalance = balance => {
    console.log('getAmountByBalance:::',balance)
    let amount = 0;
    if(+balance >= 3000){
      amount = 60;
    }
    if(+balance < 3000){
      amount = 20;
    }
    if(+balance < 500){
      amount = 10;
    }
    if(+balance < 200){
      amount = 3;
    }
    if(+balance < 50){
      amount = 1;
    }
    if(+balance < 9){
      amount = 0;
    }
    return amount
  }
  async function takeCoin() {
    // console.log(oneNft)
    // console.log(catwomanNft)
    // console.log(userBalance)
    // if(!oneNft&&!catwomanNft?.length){
    //   dispatch(setWarnContent('Sorry you have no R2E reward, only users hold ONE pass card or catwomen NFT can get $16DAO tokens.'))
    //   return;
    // }

    let params = {
      hold_amount: +`${getAmountByBalance(userBalance)}`,
      hold_balance: +`${userBalance}`,
      book_id: +bookId,
      token_address: address
    }
    let res = await rewardCreate(params)
    closePop()
    console.log(res)
    if (res?.code === 200) {
      createAlert({ type: 'success', content: 'Success!' })
      let expires = new Date(new Date() * 1 + 24 * 60 * 60 * 1000)
      Cookies.set('amount', +getAmountByBalance(userBalance)+ +userAmount, { expires })
      dispatch(setUserAmount(+getAmountByBalance(userBalance)+ +userAmount))
    }
    if (res?.code === 401) {
      createAlert({ type: 'danger', content: res.msg })
    }
    if (res?.code === 200 || res?.code === 401) {
      setGotCoin(true)
      localStorage.setItem(`coin_${bookId}_${address}`, `${getAmountByBalance(userBalance)}`)
    }
  }
  return (
    <HomeLayout>
      <div className="book book-container" id="read-container">
        {/* <Image src={bookInfo?.image_url} width={170} /> */}
        <img
          src={bookInfo?.image_url || coverErr}
          width={170}
          onError={(e) => (e.target.src = coverErr)}
        ></img>
        <div className="book-name">{bookInfo?.title}</div>
        <div className="book-description">{bookInfo?.description}</div>
        <div className="book-author flex-row">
          <div className="author-tag">Author :</div>
          <div className="author-name">{bookInfo?.author_name}</div>
        </div>
        <div className="author-info">{bookInfo?.author_profile}</div>
        <div className="flex flex-row">
          <div className="card-l ">
            <div className="flex flex-row">
              <div className="left">
                <div className="thr-title">Read to earn $16DAO</div>
                <div className="content">
                  1. After reading a novel, click the “finished” button, and the
                  system will issue corresponding rewards based on the number of
                  tokens held by the user.
                  <br />
                  2. The reward tokens for reading will be distributed to the
                  wallet on the last day of each month.
                </div>
              </div>
              <div>
                <Image src={coin} width={90}></Image>
              </div>
            </div>
            <div className="flex flex-row flex-row-space-around">
              <div className="border-div">
                <div>Number of Holding</div>
                <div>Rewards per Novel</div>
              </div>
              <div className="border-div">
                <div>10~50</div>
                <div>1</div>
              </div>
              <div className="border-div">
                <div>51~200</div>
                <div>3</div>
              </div>
              <div className="border-div">
                <div>201~500</div>
                <div>10</div>
              </div>
              <div className="border-div">
                <div>501~3000</div>
                <div>20</div>
              </div>
              <div className="border-div">
                <div>3001+</div>
                <div>60</div>
              </div>
            </div>
          </div>
          <div className="card-r flex flex-row">
            <div className="left">
              <div className="thr-title">Read on Mobile</div>
              <div className="text-left">
                <Button className="white-btn m-g-b-13" onClick={e=>window.open('https://play.google.com/store/apps/details?id=xyz.dao16.flutter_16dao&pcampaignid=web_share','_blank')}>Googole Play</Button>
                <Button className="white-btn m-g-b-13"  onClick={e=>window.open('/16dao.apk','_blank')}>Android APK</Button>
                <Button className="white-btn m-g-b-13"  variant="outline-secondary">APP Store</Button>
              </div>
            </div>
            <div>
              <Image src={app} width={130}></Image>
            </div>
          </div>
        </div>
        <div className="read-chapter ">
          <BookChapter bookChapter={bookChapter}></BookChapter>
        </div>
        <NovelChapterPop
          volumeList={bookChapter}
          bookInfo={bookInfo}
          chapterClick={(e) => readChapter(e, true)}
        ></NovelChapterPop>
      </div>
      <div className="footer">
        {/* <Button className="footer-btn" onClick={e => readNextChapter()}>Next Chapter</Button> */}
        {!address||bookChapter.filter(vol => vol?.list?.filter(chap => !chap.read).length).length ? '' : <Button className="footer-btn" onClick={e => finishRead()}>Finished</Button>}
      </div>
      <DzPop popShow={finishPopShow} closeClick={e => closePop()} >
        {/* wirter got coin  */}
        {/* <div className='m-g-b-30 p-d-t-10 p-d-l-10 p-d-r-10'>Writers can't be rewarded for reading their own novels. Read other novels can reward.</div> */}
        <div className='reader-take'>
          <div className='desc'>
            {gotCoin ?
              'You have already read the novel and received a token reward. You cannot receive an additional reward when you read it again.' :
              <div>
                <div className=''>Congratulations on the completion of reading this novel. A good novel improves your cognition and makes you experience different lives and different wonderful things. Congratulations on getting the reward of reading tokens.
                </div>
                {/* <div>The reward tokens for reading will be distributed to the wallet on the last day of each month.</div> */}
              </div>
            }
          </div>
          {/* {gotCoin ? <></> :
            <div className='m-g-l-10 m-g-r-10'>
              <div>Number Of Your Holding:  {userBalance} </div>
              <div>Rewards Per Novel: </div>
            </div>
          } */}
          <div className='coin'>
            <Image src={coin} width={100} className="coin-img"></Image>
            <div className='flex-row-center flex-row-align-baseline'>
              <span className='num heavy-title'>{getAmountByBalance(userBalance)}</span>
              {/* <span className='tag heavy-title m-g-l-4 m-g-r-4'>$16DAO</span> */}
              {/* <Image src={que} width={12}></Image> */}
            </div>
            {/* <div className='usd heavy-title'>≈294,852 USD</div> */}
          </div>
          <div className='take-btn'>
            {gotCoin ? `Rewards you get for your first reading.` : <Button className='flex-row-center' onClick={e => takeCoin()}>Got it</Button>}
          </div>
        </div>
      </DzPop>
    </HomeLayout>
  );
}
export default Book;
