import "./index.scss";

import avatar from "@/static/images/avatar.png";
import shirt from "@/static/images/shirt.png";
import phone from "@/static/images/phone.png";
import { Image } from "react-bootstrap";
function Goods() {
  return (
    <div className="goods">
      <div className="goods-title">GOODS</div>
      <div className="content">
        IP commercial authorization is enabled, personal customization for fans
        is also supported.
      </div>
      <div className="">
        <Image src={avatar} height={90}/>
        <Image src={shirt} height={90}/>
        <Image src={phone} height={90}/>
      </div>
    </div>
  );
}

export default Goods;
