import './index.scss'
import Novel from './components/novel'
import Nft from './components/nft'
import Ip from './components/ip'
function HomeLayout(props){
    return (
        <div className="home-container home-new">
            <Novel />
            {props.children}
            <Nft />
            <Ip />
        </div>
    )
}
export default  HomeLayout;