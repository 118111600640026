import { configureStore,createSlice,combineReducers  } from "@reduxjs/toolkit";
const homeSlice = createSlice({
  name:'home',
  initialState:{
    web3:null,
    loading:false,
    address:'',
    warnContent:'',
    showWarn:false,
    oneNft:null,
    catwonmanNft:[],
    userBalance:null,
    userAmount:null,
    accountPopShow:false,
  },
  reducers:{
    setWeb3(state,{payload}){
      state.web3 = payload
    },
    setLoadingCoverStatus(state,{payload}){
      state.loading = payload
    },
    setAddress(state,{payload}){
      state.address = payload
    },
    setWarnContent(state,{payload}){
      state.warnContent = payload
    },
    setShowWarn(state,{payload}){
      state.showWarn = payload
    },
    setOneNft(state,{payload}){
      state.oneNft = payload
    },
    setCatwomanNft(state,{payload}){
      state.catwonmanNft = payload
    },
    setUserBalance(state,{payload}){
      state.userBalance = payload
    },
    setUserAmount(state,{payload}){
      state.userAmount = payload
    },
    setAccountPopShow(state,{payload}){
      state.accountPopShow = payload
    }
  }
})
export const {setLoadingCoverStatus,setAddress,setWarnContent,setShowWarn,setWeb3,setOneNft,setCatwomanNft,setUserBalance,setUserAmount,setAccountPopShow} = homeSlice.actions
const reducer = combineReducers({
  home:homeSlice.reducer
})
const store = configureStore({reducer})
export default store;