import './index.scss'
import BookList from './components/bookList'
import Read2Earn from './components/read2Earn'
import AboutCoin from './components/aboutCoin'
function R2e() {
    return (
        <>
            <div className='container r2e'>
                <Read2Earn />
                <BookList />
                {/* <AboutCoin /> */}
            </div>
        </>
    )
}
export default R2e