import './index.scss'
import boat from '@/static/images/SB-os.png'
import catwoman1 from '@/static/images/catwoman1.jpeg'
import catwoman2 from '@/static/images/catwoman2.jpeg'
import catwoman3 from '@/static/images/catwoman3.jpeg'
import catwoman4 from '@/static/images/catwoman4.jpeg'
import catwoman5 from '@/static/images/catwoman5.png'
import catwoman6 from '@/static/images/catwoman6.jpg'
import catwoman7 from '@/static/images/catwoman7.jpg'
import catwoman8 from '@/static/images/catwoman8.jpg'
import catwoman9 from '@/static/images/catwoman9.png'
import { Image } from 'react-bootstrap'

function Nft(){
    return (
        <div className='nft-card'>
            <div className='title'>NFT</div>
            <p className='content'>Designing NFT based on novels provides source materials for IP incubation. The catwomen series of NFTs is developed based on the novel “Divorce Day”.</p>
            <div className='flex flex-row flex-row-space-between'  onClick={e=>window.open('https://opensea.io/DZLabs')}>
                <div className='opensea flex flex-row flex-row-space-between'>
                    <Image src={boat} width={24} className='m-g-r-4'></Image>
                    OpenSea
                </div>
                <div className='collect pointer'>Collecet>></div>
            </div>
            <div className='cat-women'>
            <Image src={catwoman1} width={75}></Image>
            <Image src={catwoman2} width={75}></Image>
            <Image src={catwoman3} width={75}></Image>
            <Image src={catwoman4} width={75}></Image>
            <Image src={catwoman5} width={75}></Image>
            <Image src={catwoman6} width={75}></Image>
            <Image src={catwoman7} width={75}></Image>
            <Image src={catwoman8} width={75}></Image>
            <Image src={catwoman9} width={75}></Image>
            </div>
        </div>
    )
}

export default Nft