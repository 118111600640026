import { object } from 'bfj/src/events'
import { useState, useRef, useCallback, useEffect } from 'react'
export function groupBy(arr, key, name) {
  const groupList = arr.filter(item => Reflect.has(item, key))
  const keysList = Array.from(new Set(groupList.map(i => i[key]))).sort((m, n) => m - n)
  let resArr = []
  keysList.forEach((item) => {
    const vol = groupList.find(i => i[key] === item)
    resArr.push({
      volume: vol.volume,
      name: vol[name],
      list: groupList.filter(i => i[key] === item)
    })
  })
  return resArr;
}
export function useDebounce(fn, delay, dep = []) {
  const { current } = useRef({ fn, timer: null })
  useEffect(function () {
    current.fn = fn
  }, [fn])

  return useCallback(function f(...args) {
    if (current.timer) {
      clearTimeout(current.timer);
    }
    current.timer = setTimeout(() => {
      current.fn.call(this, ...args);
    }, delay);
  }, dep)
}
export function useThrottle(fn, delay, dep = []) {
  const { current } = useRef({ fn, timer: null })
  useEffect(function () {
    current.fn = fn
  }, [fn])
  return useCallback(function (...args) {
    if (!current.timer) {
      current.timer = setTimeout(() => {
        delete current.timer
      }, delay)
      current.fn.call(this, ...args)
    }
  }, dep)
}
export function deepClone(obj = {}) {
  let newObj = null;
  if (typeof (obj) === 'object' && obj !== null) {
    newObj = obj instanceof Array ? [] : {}
    for (let k in obj) {
      newObj[k] = deepClone(obj[k])
    }
  }
  else {
    newObj = obj
  }
  return newObj
}
// export function useThrottle(fn, delay, dep = []) {
//   const [lastExecTime, setLastExecTime] = useState(0);

//   useEffect(() => {
//     const handler = setTimeout(() => {
//       fn();
//       setLastExecTime(Date.now());
//     }, delay - (Date.now() - lastExecTime));

//     return () => {
//       clearTimeout(handler);
//     };
//   }, dep);

//   return () => {
//     clearTimeout(lastExecTime);
//   };
// }