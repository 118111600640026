import './index.scss'
import showCoin from '@/static/images/show-coin.png'
import coingif from '@/static/images/coin.gif'
import verifyok from '@/static/images/verifyok.png'
import verifyno from '@/static/images/verifyno.png'
import twitter from '@/static/images/twitter.png'
import ruletable from '@/static/images/ruletable.png'
import { createAlert } from '@/components/dzAlert';
import { useState, useRef, useEffect, useContext, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Image, Button, ProgressBar, Alert } from 'react-bootstrap'
import { setWarnContent, setShowWarn, } from '@/store';
import { eventEmitter } from '@/store/eventEmitter';
import { userReceiveReport, receiveReport2, receive2, getAuthTokenRequest, getAirdropAvailable, postAirdrop, getAirdropStage } from '@/data';
import { useNavigate, useLocation } from 'react-router-dom';
import { TwitterShareButton } from 'react-share';
import Phase from '../phase'
import { useDebounce, useThrottle, deepClone } from '@/utils'
function Claim3() {
  const location = useLocation();
  const limitBalance = 10
  const [airdropState, setAirdropState] = useState('unreport') // unreport invite finished
  const { address } = useSelector(state => state.home)
  const [user_airdrop_amount, set_user_airdrop_amount] = useState(0)
  const [user_airdrop_amount2, set_user_airdrop_amount2] = useState(0)
  const [total_invite, set_total_invite] = useState(0)
  const [total_airdrop_amount, set_total_airdrop_amount] = useState(0)
  const [quota, set_quota] = useState(0)
  const { userBalance } = useSelector(state => state.home)
  const [total_airdrop_recipient, set_total_airdrop_recipient] = useState(0)
  const [is_valid, set_is_valid] = useState(true)
  const [is_follower, set_is_follower] = useState(false)
  const text = "I just claimed $16DAO, over 20,000 people have already claimed it. Let’s meet in the same great economic plantform. CLAIM NOW! 👇\r\n"
  const hashtags = ["Airdrop", "web3", "16DAOCoin", "Giveaway"]
  const shareUrl = `${window.location.href.split('?').at(0)}?inviter=${address}`
  const [inviterAddress, setInviterAddress] = useState()
  useEffect(() => {
    console.log('total_airdrop_amount:', total_airdrop_amount)
    if (+total_airdrop_amount >= 700000) {
      setAirdropState('finished')
    }
  }, [total_airdrop_amount, address])
  useEffect(() => {
    console.log('location:', location)
    console.log('window.localStorage:', window.localStorage.getItem('inviterAddress'))
    if (location.search) {
      const param = new URLSearchParams(location.search)
      const inviterAddress = param.get('inviter')
      console.log('param:', param)
      console.log('inviterAddress:', inviterAddress)
      window.localStorage.setItem('inviterAddress', inviterAddress)
      setInviterAddress(inviterAddress)
    }
  }, [location])
  useEffect(() => {
    if (!address) {
      set_user_airdrop_amount(0)
      set_user_airdrop_amount2(0)
      setAirdropState('unreport')
    }
    else {
      queryUserReceive()
      getTodayAirdropAvailable()
    }
  }, [address])
  useEffect(() => {
    receiveReportHandle()
  }, [])
  useEffect(() => {
    console.log('user_airdrop_amount:', user_airdrop_amount)
    if (user_airdrop_amount && +total_airdrop_amount < 700000) {
      setAirdropState('invite')
    }
  }, [user_airdrop_amount, total_airdrop_amount])

  const claimHandle = () => {
    if (!address) {
      eventEmitter.emit('connectEvent', true)
      // dispatch(setWarnContent('Please authorize to access your account!'))
      return;
    }
    console.log('address:', address)
    if(!is_follower){
      createAlert({ type: 'danger', content: 'Follow DZ Labs on twitter Please' })
      return;
    }
    if (userBalance >= limitBalance) {
      claimRequest(address)
    }
  }
  /**
   * mint 领coin 
   *  */
  const claimRequest = async (address) => {
    console.log("inviterAddress:", inviterAddress)
    const res = await getAirdropStage({ "user_token_address": address })
    if (res?.code === 200) {
      const { code, data } = await postAirdrop({
        claim_stage: res?.data?.claim_stage || 0 ,
        user_token_address: address
      })
      if (code === 200) {
        createAlert({ type: 'success', content: 'It has been minted successfully,please check the balance in your wallet.If there are still tokens to claim,please try again later.' })
      }
      else {
        createAlert({ type: 'danger', content: 'Your airdrop has been collected, but you can read to earn more tokens right now.' })
      }
    }
  }
  /**
   * 查询用户领取数量、邀请人数
   */
  const queryUserReceive = async () => {
    const res = await userReceiveReport({ "user_token_address": address })
    if (res?.code === 200) {
      const { total_airdrop_amount, total_airdrop_amount2, total_invite, is_valid } = res?.data || {}
      set_user_airdrop_amount(total_airdrop_amount || 0)
      set_user_airdrop_amount2(total_airdrop_amount2 || 0)
      set_total_invite(total_invite > 1000 ? 1000 : (total_invite || 0))
      set_is_valid(is_valid)
    }

  }
  /**
   * 查询总领取量
   */
  const receiveReportHandle = async () => {
    const res = await receiveReport2()
    if (res?.code === 200) {
      const { total_airdrop_amount, total_airdrop_recipient } = res.data || {}
      set_total_airdrop_amount(total_airdrop_amount || 0)
      set_total_airdrop_recipient(total_airdrop_recipient)
    }
  }
  const numFormat = number => {
    number = number > 0 ? number : 0
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  const verifyFollowStatus = () => {
    window.open('https://twitter.com/16DAOCoin', '_blank')
    setTimeout(() => {
      set_is_follower(true)
    }, 3000)
  }
  const getTodayAirdropAvailable = async () => {
    const { code, data } = await getAirdropAvailable({ "user_token_address": address })
    if (code === 200) {
      set_quota(data.available)
    }
  }
  return (
    <div className='claim '>
      <Image src={coingif} className="coin"></Image>
      {/*<div className='mint-container flex-row-space-between'>
        <div className='mint-box flex-row-space-between'>
          <div class="flex flex-row">
            <div className='key'>Today's Quota</div>
            <div className='val'>{numFormat(quota)}</div>
          </div>
          <Button  onClick={claimHandle} className={userBalance < limitBalance && address?'mint-btn disabled-btn':'mint-btn'}>Free to Mint</Button>
        </div>
      </div>
      <div className='mission'>
        <b>MISSIONS TO MINT</b> ① Hold at least 10 $16DAO (click here to <a href="https://www.xt.com/en/trade/16dao_usdt" target="_blank">buy</a>)
        {
          address ?
            userBalance >= limitBalance ?
              <Image src={verifyok} className="verify-ok"></Image> :
              <Image src={verifyno} className="verify-ok"></Image> :
            <button onClick={claimHandle}>Verify</button>
        }
        ② Follow DZ Labs on twitter
        {
          is_follower ?
            <Image src={verifyok} className="verify-ok"></Image> :
            <button onClick={verifyFollowStatus}>Follow</button>
        }
      </div>
      <div className='claim-rules'>
        <div className='rule-title'>Rules:</div>
        <div className='rule-item'>💸 Total amount of airdrops obtained and the amount that can be claimed each time:</div>
      </div>
      <img className='rule-img' src={ruletable} />*/}
      <Phase user_airdrop_amount1={user_airdrop_amount} user_airdrop_amount2={user_airdrop_amount2} isViolation={!is_valid} airdropState={airdropState}></Phase>
    </div>
  )
}
export default Claim3