import './index.scss'
import novel5 from '@/static/images/novel5.png'
import { Image, Button } from 'react-bootstrap';
import coverErr from '@/static/images/coverErr.png'
import ClearDiv from '@/components/clearDiv';
function StoryCard(props) {
    console.log(props)
    return (
        <div className='story-card'>
            <ClearDiv>
                <div className='story-card-main flex-row-space-between'>
                    {/* <Image src={props.book?.image_url||novel5} width={130} className="story-cover" /> */}
                    <img  src={props.book?.image_url||coverErr} onError={e=>e.target.src=coverErr} width={130} className="story-cover" ></img>
                    <div className='story-info'>
                        <div className='story-title text-line-1'>{props.book?.title}</div>
                        <div className='story-author flex-row'>
                            <div className='tag'>Author :</div>
                            <div className='text-line-1 content'>{props.book?.author_name}</div>
                        </div>
                        <div className='story-description text-line-2'>{props.book?.description}</div>
                        {/* 未提交or审核失败 */}
                        {['0','1'].includes(`${props?.book?.status}`) ?
                            <div className='story-status flex-row'>
                                <Button variant="outline-secondary"  onClick={e => props?.onEditClick?.(props.book)} >Edit</Button>
                                <Button variant="outline-secondary"  onClick={e => props?.onChapterClick?.(props.book)}>Manage Chapter</Button>
                                <Button variant="outline-secondary"  onClick={e => props?.onEndClick?.(props.book)}>End Application</Button>
                            </div>:
                          // 审核中
                          `${props?.book?.status}` === '3' ?
                            <div className='story-status flex-row'>Application under review, cannot edit</div>:
                          // 审核通过(已上架)
                          `${props?.book?.status}` === "4" ?'':
                          // 已下架
                          +props?.book?.status === '2' ? <div className='story-status flex-row'>Off the shelves, user cannot read</div>:''
                        }
                    </div>
                </div>
            </ClearDiv>
        </div>
    )
}
export default StoryCard;