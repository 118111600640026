import './index.scss'
import txIcon from '@/static/images/txIcon.png'
import tx from '@/static/images/tx.png'
import fruitIcon from '@/static/images/fruitIcon.png'
import fruit from '@/static/images/fruit.png'
import play from '@/static/images/play.png'
import music from '@/static/images/music-avatar.png'
import Goods from '../goods'
import { Image } from 'react-bootstrap'
function Ip(){
    return (
        <div className='right-box'>
            <div className='ip-card'>
                <div className='ip-title'>IP</div>
                <div className='content'>The IP incubation result of NFT catwomen is to obtain singer status on music platform with 500 million users and release more than 20 songs.</div>
                <div className='music m-g-b-10'>
                    <Image src={txIcon} height={24}/>
                    <Image src={tx}  height={24}/>
                    <Image src={fruitIcon} className='m-g-l-8' height={30}/>
                    <Image src={fruit}  height={18}/>
                </div>
                <div className='player flex flex-row pointer' onClick={e=>window.open('https://c6.y.qq.com/base/fcgi-bin/u?__=6iGlaxbKjUDz')}>
                    <Image src={music}  height={80} className='m-g-r-10'/>
                    <div className='flex flex-col items-start m-g-r-10'>
                        <div className='m-g-b-4'>catwomen #0401</div>
                        <div>晴川街</div>
                    </div>
                    <Image src={play}  height={24}/>
                </div>
            </div>
            <Goods />
        </div>
    )
}

export default Ip