import './index.scss'
import ClearDiv from '@/components/clearDiv';
import { Button } from 'react-bootstrap';
import StoryCard from './components/storyCard';
import { useNavigate } from 'react-router-dom';
import { getBookList, getBookListByUser, updateBook } from '@/data';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoadingCoverStatus } from '@/store';
import { isMock,mockBookList } from '@/mock';
function MyStory() {
    const jumpTo = useNavigate()
    const dispatch = useDispatch()
    const [bookList, setBookList] = useState([])
    useEffect(() => {
        updateBookList()
    }, [])
    async function updateBookList() {
        dispatch(setLoadingCoverStatus(true))
        if(isMock){
            mockBookList.data = mockBookList.data.list
        }
        const res = isMock?mockBookList:await getBookListByUser({
            "limit": 200,
            "offset": 0,
            "title": ""
        })
        dispatch(setLoadingCoverStatus(false))
        if (res?.data) {
            setBookList(res.data || [])
        }
    }

    async function updateBookInfo(bookInfo) {
        bookInfo.status = 3
        const res = await updateBook(bookInfo)
        updateBookList()
    }
    return (
        <div className=' my-story'>
            <div className='about-writer'>
                <div className='about-title'>About writers</div>
                <div className='sub-title'>Earn money by posting novels</div>
                <div className='about-desc'>Writers's novels have barely had chances to be adapted into movies and receive commercial payments. Online literature platform unfair terms make writers lose the copyright of their works. The platform monopolizes novel copyright for at least five years, at most 50 years after the author's death. But DZ Labs returns the copyright to the writers, allowing them to become DAO members and letting them drive the DAO organization to grow. And earning with DZ Labs.</div>
                <div className='sub-title'>What the writer will get?</div>
                <div className='about-desc'>When the novel is adapted into GameFi, NFT, and movies, the commercialized income will be returned to the writer.
                    Become a member of DZ DAO, deeply participate in the planning and development of platform, and enjoy the benefits brought by the development and growth of the platform.</div>
            </div>
            <div className='my-story-container'>

                <div className='my-story-title'>My Stories</div>
                <div className='tips'>Tips: After novel is approved, platform will contact publisher to sign a contract. Then novels cannot be modified and users can start reading the them.</div>
                <div className='btns-group flex-row'>
                    {/* <Button variant="outline-secondary" className='about-btn'>About writers</Button> */}
                    <Button className='create-btn' onClick={e => jumpTo('/storyinfo')}>Create+</Button>
                </div>
                <div className='my-story-list flex-row-space-between flex-row-wrap'>
                    {bookList.map(book =>
                        <StoryCard
                            book={book}
                            key={book.id}
                            onEditClick={e => jumpTo(`/storyinfo?bookId=${book.id}`)}
                            onChapterClick={e => jumpTo(`/volume?bookId=${book.id}`)}
                            onEndClick={e => updateBookInfo(book)}
                        />)
                    }
                </div>
            </div>
        </div>
    )
}
export default MyStory;