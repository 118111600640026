import './index.scss'
import { Button, Image } from 'react-bootstrap'
import novel from '@/static/images/novel.png'
import { useEffect, useState, useRef } from 'react'
import { getBookList } from '@/data';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoadingCoverStatus } from '@/store';
import coverErr from '@/static/images/coverErr.png'
import useBookList from '@/hooks/useBookList';
import AllNovelDialog from './components/allNovelDialog/index'
function Novel() {
    const [showNovelList, setShowNovelList] = useState(true)
    const { bookList } = useBookList({pageSize:12})
    const novelListRef = useRef()
    const jumpTo = useNavigate()
    const dispatch = useDispatch()
    const toggleNovelList = async () => {
        if (!showNovelList) {
            novelListRef.current.scroll({ top: 0, behavior: 'smooth' })
            // window.scroll({ top: 0, behavior: 'smooth' })
        }
        setShowNovelList(!showNovelList)
    }
    const [showAll, setShowAll] = useState(false)
    const showAllNovels = () => {
        console.log('showalllist')
        setShowAll(true)
    }
    return (
        <>
            {/* <div className='toggle-btn'>
                <Button onClick={e => toggleNovelList()}>{showNovelList ? '<<' : '>>'}</Button>
            </div> */}
            <div ref={novelListRef} className={showNovelList ? 'novel-open novel flex flex-col items-center' : 'novel flex flex-col items-center'}>
                <div className='novel-title'>NOVEL</div>
                <div className='all flex flex-row' onClick={e => showAllNovels()}>
                    <Image src={novel} width={40}></Image>
                    All>>
                </div>
                <div className='novel-list flex flex-col'>
                    {bookList.map(book => <Image className='novel-item' width={70} onClick={e => jumpTo(`/book?id=${book.id}`)} key={book.id} src={book.image_url || coverErr} onError={e => e.target.src = coverErr}></Image>)}
                </div>
            </div>
            <AllNovelDialog show={showAll} onClose={e=>setShowAll(false)}></AllNovelDialog>
        </>
    )
}
export default Novel