import './index.scss'
import { Alert } from 'react-bootstrap'
import { useState,useEffect,useRef } from 'react';
import ReactDOM from 'react-dom/client';
import React from 'react';
let alertRoot;
function DzAlert({type,content,root}){
  const alertRef = useRef()
  const [show, setShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
        setShow(false)
        root.unmount()
        alertRoot = null
    }, 3000)
  })
  useEffect(() => {
    setTimeout(() => {
      if (show) {
        setShow(false)
      }
    }, 2000)
  }, [show])
  return (
    <Alert ref={alertRef}  key={type} variant={type} show={show} style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%,-50%)',zIndex:99999999 }}>{content}</Alert>
  )
}
export function initAlert() {
  const body = document.querySelector('body');
  if (!document.querySelector('#alert-container')) {
    const alertContainer = document.createElement('div')
    alertContainer.id = 'alert-container'
    body.append(alertContainer)
  }
  alertRoot = ReactDOM.createRoot(document.querySelector('#alert-container'));
}
export function createAlert({ type, content }){
  if(!alertRoot){
    alertRoot = ReactDOM.createRoot(document.querySelector('#alert-container'));
  }
  alertRoot.render(<DzAlert root={alertRoot} type={type} content={content}></DzAlert>
  );
}