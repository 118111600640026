import './App.scss';
import HeaderContainer from '@/components/headerContainer'
import FooterContainer from '@/components/footerContainer'
import { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from '@/views/home/index.js'
import R2e from '@/views/r2e/index.js'
import Book from '@/views/book/index.js'
import Read from '@/views/read/index.js'
import Airdrop from '@/views/airdrop/index.js'
import MyStory from '@/views/myStory/index.js'
import StoryInfo from '@/views/storyInfo/index.js';
import Volume from './views/volume';
import Chapter from './views/chapter';
import Token from './views/token';
import Loading from './components/loading';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from '@/store'
import DzAlert from './components/dzAlert';
import { mainEventContext as MainEventContext,eventEmitter } from './store/eventEmitter';
function App() {
  let location = useLocation()
  useEffect(() => {
    console.log('location:',location)
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [location])
  return (
    <Provider store={store}>
      <MainEventContext.Provider value={eventEmitter} >
        <div className="App">
          {/* <Loading></Loading> */}
          {/* <DzAlert></DzAlert> */}
          <HeaderContainer />
          <Routes>
            <Route path="/" element={<Home />}>
            </Route>
            <Route path="/index" element={<Home />}>
            </Route>
            <Route path="/home" element={<Home />}>
            </Route>
            <Route path="/r2e" element={<R2e />}>
            </Route>
            <Route path="/book" element={<Book />}>
            </Route>
            <Route path="/read" element={<Read />}>
            </Route>
            <Route path="/airdrop" element={<Airdrop />}>
            </Route>
            <Route path="/mystory" element={<MyStory />}>
            </Route>
            <Route path="/storyinfo" element={<StoryInfo />}>
            </Route>
            <Route path="/volume" element={<Volume />}>
            </Route>
            <Route path="/chapter" element={<Chapter />}>
            </Route>
            <Route path="/token" element={<Token />}>
            </Route>
            
          </Routes>
          {['/home','/book'].includes(location.pathname)?'':<FooterContainer />}
          
        </div>
      </MainEventContext.Provider>
    </Provider>
  );
}

export default App;
