import './index.scss'
import ClearDiv from '@/components/clearDiv'
function tokenCard({index,title,desc}){
  return (
      <ClearDiv className='token-card'>
        <div className='card-index heavy-title'>{index}</div>
        <div className='card-title heavy-title'>{title}</div>
        <div className='card-desc'>{desc}</div>
      </ClearDiv>
  )
}
export default tokenCard