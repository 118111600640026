import './index.scss'
import { Image } from 'react-bootstrap';
import novel1 from '@/static/images/novel1.png'
import { useEffect, useRef, useState,useImperativeHandle } from 'react';
import { uploadFile } from '@/data';
import { createAlert } from '../dzAlert';
import coverErr from '@/static/images/coverErr.png'
function ImageUploader({
  imageUrl,
  load,
  onRef
}) {
  const uploadFileRef = useRef()
  let [curUploadFile, setCurUploadFile] = useState({})
  const [fileValue, setFileValue] = useState()
  const uploadFileFc = e => {
    uploadFileRef.current.click();
  }
  useImperativeHandle(onRef,()=>{
    return {
      submitUpload
    }
  })
  const submitUpload = async ()=>{
    let formData = new FormData()
    formData.set('uploadfile', curUploadFile)
    let res = await uploadFile(formData)
    if(res?.code === 200){
      const {file_url} = res.data;
      setFileValue(file_url)
      load(file_url)
      return file_url
    }
  }
  const uploadFileChange = async e => {
    const curFile = e.target.files[0]
    const isValidFile = checkFileType(curFile)
    if (isValidFile) {
      const localSrc = e.target.value
      const loaclFile = new FileReader()
      loaclFile.readAsDataURL(e.target.files[0])
      loaclFile.onload = (res) => {
        load(res.currentTarget.result)
        setCurUploadFile(curFile)
      }
    }

  }
  const checkFileType = file => {
    const { type, size } = file
    let checkType = true;
    const limitSize = 1024 * 1024 * 5 // 5M
    const supportTypeList = ['image/png', 'image/jpg', 'image/jpeg']

    if (!supportTypeList.includes(type)) {
      console.log('invalid type', uploadFileRef)
      console.log(imageUrl)
      setFileValue(imageUrl)
      createAlert({ type: 'danger', content: 'image type is invalid' })
      checkType = false;
      return checkType
    }
    if (size > limitSize) {
      console.log('invalid size', uploadFileRef)
      console.log(imageUrl)
      setFileValue(imageUrl)
      createAlert({ type: 'danger', content: 'image size is invalid' })
      checkType = false;
      return checkType;
    }
    return checkType;
  }
  return (
    <div className='dz-image-uploader'>
        {imageUrl ?
        <img src={imageUrl || coverErr} width={170} height={208} style={{ objectFit: 'contain' }} onClick={e => uploadFileFc(e)} onError={e=>e.target.src=coverErr} ></img>
          // <Image src={imageUrl || null} width={170} height={208} style={{ objectFit: 'contain' }} onClick={e => uploadFileFc(e)} />
          :
          <div className='upload-btn h-min-110' onClick={e => uploadFileFc(e)}></div>
        }
        <input type='file' accept="image/png, image/jpeg, image/jpg" defaultValue={fileValue} className='hidden' ref={uploadFileRef} onChange={e => uploadFileChange(e)} name="uploadfile"/>
    </div>
  )
}
export default ImageUploader;