import './index.scss'
import EarnCard from './components/earnCard';
import onepass from '@/static/images/onepass.png'
import catwomen from '@/static/images/R2E-catwomen.png'
import { Image } from 'react-bootstrap';
function Read2Earn() {
  const cats = []
  for (let i = 0; i < 12; i++) {
    cats.push(require(`@/static/images/cat${i+1}.png`))
  }
  console.log(cats)
  return (
    <div className='read-earn'>
      <div className='read-earn-title'>Reading novel to earn $16DAO</div>
      <div className='reword-for border-bt'>
        <div className='reword-title'>Rewards For Token Holders <span className='live'>( live )</span></div>
        <div className='reword-content'>At the end of each novel, when you tap the "Finish” button, system will check whether your wallet is holding tokens or not; and according to the number of tokens held, rewards will be issued according to the level. The more token you hold, more rewards you will get.</div>
      </div>
      <div className='reword-for'>
        <div className='reword-title'>Rewards For NFT Holders <span className='soon'>( soon )</span></div>
        <div className='reword-content'>Excellent novels can improve people's mental health, and can also give people a new experience of life by feeling the life of the protagonist of the novel, the novels under DZ Labs belong to this type. Read on, you can also earn $16DAO tokens while improving yourself, which can be earned by holding one NFT from Catwoman NFTs.</div>
      </div>
      {/* <div className="read-earn-content">Excellent novels can improve people's mental health, and can also give people a new experience of life by feeling the life of the protagonist of the novel, the novels under DZ Labs belong to this type. Read on, you can also earn $16DAO tokens while improving yourself, which can be earned by holding one NFT from Catwoman NFTs.</div> */}
      <div className="cat-list flex-row">
        <div className="cats text-line-1">
          {cats.map(i=><Image src={i} width={44} ></Image>)}
        </div>
        <div className="collect text-line-1"><a href="https://opensea.io/collection/catwomanstar" target='_blank'>Collect Catwoman Now &gt;&gt; </a></div>
      </div>
      {/* <div className='read-earn-content'>Excellent novels can improve people's mental health, and can also give people a new experience of life by feeling the life of the protagonist of the novel, the novels under DZ Labs belong to this type. Read on, you can also earn $16 DAO tokens while improving yourself, which can be earned by meeting the following tow conditions:</div> */}
      {/* <div className='earn-card-container flex-row-space-between'>
        <EarnCard index="01"
          collectClick={e=>window.open("https://opensea.io/assets/ethereum/0x2fd91e9b242b090b4f6c13273ad2573a02cd4c9f/1/", '_blank')}
          title="Holding ONE pass card"
          icon={onepass}
          content={`Wallet addresses that have held "ONE" pass card NFT can also read novels to earn tokens. And "ONE" is the only super user token of DZ Labs.`} />
        <div className='devide'></div>
        <EarnCard index="02"
          collectClick={e=>window.open("https://opensea.io/collection/catwomanstar", '_blank')}
          title="Holding 1 catwomen NFT"
          icon={catwomen}
          content={`The catwomen NFTs developed based on the novels of DZ Labs. The wallet address holding the NFTs can read novels to earn tokens.`} />
      </div> */}
    </div>
  )
}
export default Read2Earn;