import './index.scss'
import { ToastContainer, Toast,Image } from 'react-bootstrap';
import closeBtn from '@/static/images/closeBtn.png'
import { useEffect } from 'react';
function DzPop({
    children,
    popShow=false,
    toggleShow,
    content,
    closeClick
}) {
    useEffect(()=>{
    },[])
    return (
        <ToastContainer position='middle-center' className='dz-pop'>
            <Toast bg="light" show={popShow}>
                <Toast.Body>
                    <Image src={closeBtn} width={20} onClick={e=>closeClick()} className="close-btn pointer"/>
                    <div className='toast-content'>
                        {children}
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}
export default DzPop;