import { useEffect, useRef } from 'react';
import './index.scss'
import Form from 'react-bootstrap/Form';
function FormRadioGroup(props) {
    const ref = useRef();
    useEffect(() => {
        console.log('dz-radio-group:',props)
        // ref.current.value = props.defaultValue || ''
    }, [])
    // useEffect(() => {
    //     ref.current.value = props.defaultValue || ''
    // }, [props])
    const handleRadioChange = e =>{
        const val = e.target.value
        console.log('val:',val)
        props.onChange(val)
    }
    return (
            <div className='dz-radio-group'>
                <Form.Check
                    inline
                    label="English"
                    name="language"
                    type={'radio'}
                    value="en"
                    checked={props.defaultValue === 'en'}
                    onChange={e=>handleRadioChange(e)}
                />
                <Form.Check
                    inline
                    label="简体/繁体中文"
                    name="language"
                    type={'radio'}
                    value="cn"
                    checked={props.defaultValue === 'cn'}
                    onChange={e=>handleRadioChange(e)}
                />
            </div>
    )
}
export default FormRadioGroup;